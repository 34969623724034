import './localflow.css';
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../contexts/auth';

import Header from '../../components/Header';
import Title from '../../components/Title';

import { MdRestaurantMenu } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import firebase from '../../services/firebaseConnection';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import ModalTable from '../../components/ModalTable';


const useStyles = makeStyles({
  root: {
    width: 300,
  },
});



export default function LocalFlow() {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const [tables, setTables] = useState([])
  const listRef = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('mesas').orderBy('identificacao', 'asc');
  const linkRef = firebase.firestore().collection('pdvUsers').doc(user.uid);

  const [occupation, setOccupation] = useState();
  const [occupationValueBD, setOccupationValueBD] = useState();
  const [timePerRequest, setTimePerRequest] = useState();
  const [timePerRequestValueBD, setTimePerRequestValueBD] = useState();

  const [showModalTable, setShowModalTable] = useState(false);
  const [detailTable, setDetailTable] = useState();
  const [isPdvOpen, setIsPdvOpen] = useState(false);
  const [timeClosePdv, setTimeClosePdv] = useState('');
  const [timeOpenPdv, setTimeOpenPdv] = useState('');


  useEffect(() => {

    async function loadTables() {
      await listRef.onSnapshot((doc) => {
        let listTables = [];
        doc.forEach((item) => {
          listTables.push({
            idTable: item.id,
            identificacao: item.data().identificacao,
            status: item.data().situacao,
            //createdFormated: format(item.data().created.toDate(), 'dd/MM - HH:mm:s' ),
            lugares: item.data().lugares,
            ocupantes: item.data().ocupantes

          })


        });


        setTables(listTables);

      })


      await linkRef.onSnapshot((doc) => {

        setOccupationValueBD(doc.data().ocupacaoLocal);
        setTimePerRequestValueBD(doc.data().tempoPedido);
        setIsPdvOpen(doc.data().isPdvOpen);
        setTimeOpenPdv(doc.data().timeOpenPdv);
        setTimeClosePdv(doc.data().timeClosePdv);


      });


    }

    loadTables();
  }, []);

  async function openPdv() {
    await firebase.firestore().collection('pdvUsers').doc(user.uid).update({ isPdvOpen: true });
  }

  async function closePdv() {
    await firebase.firestore().collection('pdvUsers').doc(user.uid).update({ isPdvOpen: false });
  }

  async function updateTimeOpenPdv() {
    await firebase.firestore().collection('pdvUsers').doc(user.uid).update({ timeOpenPdv: timeOpenPdv });
  }

  async function updateTimeClosePdv() {
    await firebase.firestore().collection('pdvUsers').doc(user.uid).update({ timeClosePdv: timeClosePdv });
  }

  

  async function clearTable(id) {
    await linkRef.collection('mesas').doc(id).update({ situacao: true });
  }
  async function clearOccupants(id) {
    await linkRef.collection('mesas').doc(id).update({ ocupantes: parseInt(0) });
  }

  async function occupyTable(id) {
    await linkRef.collection('mesas').doc(id).update({ situacao: false });
  }

  //chamado quando troca de ocupação
  const handleChangeOccupation = (event, newValue) => {
    setOccupation(newValue);//atualiza o a state
  }

  async function updateOccupation() {//salva valor no banco de dados
    await linkRef.update({ ocupacaoLocal: occupation });

  }

  //chamado quando troca de ocupação
  const handleChangeTime = (event, newValue) => {
    setTimePerRequest(newValue);//atualiza o a state
  }
  async function updateTimePerRequest() {//salva valor no banco de dados
    await linkRef.update({ tempoPedido: timePerRequest });

  }

  function toggleModalTable(item) {
    setShowModalTable(!showModalTable) // trocando de true para false
    setDetailTable(item);

  }



  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Mesas e Fluxo">
          <MdRestaurantMenu size={25} />
        </Title>


        <>
          <div style={{ flexDirection: 'column', width: '60%' }}>
            <div>
              <label>O restaurante está aberto ou fechado?<label style={{ color: isPdvOpen === true ? 'rgba(0, 255, 0, .9)' : 'rgba(255, 0, 0, 0.7)' }}>{isPdvOpen === true ? ' Aberto' : ' Fechado'}</label></label>
              <br /><span>Clique para alterar:</span>
              <button style={{padding: 2, margin: 1, backgroundColor: 'rgba(0,0,0,.5)', color: '#fff', borderRadius: 4, border: 0}} onClick={() => closePdv()}>Fechado</button>
              <button style={{padding: 2, margin: 1, backgroundColor: 'rgba(0,0,0,.5)', color: '#fff', borderRadius: 4, border: 0}} onClick={() => openPdv()}>Aberto</button>
              <br />    <br />
              <label>Horário de inicio: </label>
              <i>{timeOpenPdv} </i>
              <input type="time" style={{border: 0, borderRadius: 4, margin: 2}} onChange={(e) => setTimeOpenPdv(e.target.value)} />
              <button onClick={()=> updateTimeOpenPdv()}>Alterar</button>
              
              <br/>
              <label>Horário de fechamento: </label><i>{timeClosePdv}</i>
              <input type="time" style={{border: 0, borderRadius: 4, margin: 2}}  onChange={(e) => setTimeClosePdv(e.target.value)} />
              <button onClick={()=> updateTimeClosePdv()}>Alterar</button>
              
              <br/><i>*para que seja alterado, após selecionar o horário, clique em "Alterar"</i>
              <br /><br />
            </div>
            <div >
              <Typography id="discrete-slider" gutterBottom>
                Ocupação atual: {occupationValueBD}%
              </Typography>
              <Slider
                color="#000"


                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                defaultValue={occupationValueBD}
                onChange={handleChangeOccupation}
                step={5}
                marks
                min={10}
                max={100}
              />
              <button className="btn-flow" onClick={updateOccupation}>Alterar</button>
            </div>
            {/*  */}
            <div style={{ marginTop: '10px' }}>
              <Typography id="discrete-slider" gutterBottom>
                Tempo médio por pedido: {timePerRequestValueBD} minutos
              </Typography>
              <Slider
                color="#000"


                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                defaultValue={occupationValueBD}
                onChange={handleChangeTime}
                step={1}
                marks
                min={10}
                max={80}
              />
              <button className="btn-flow" onClick={updateTimePerRequest}>Alterar</button>
            </div>
          </div>




          <Link to="/newtable" className="new">
            <FiPlus size={25} color="#fff" />
            Nova mesa
          </Link>

          <table>
            <thead>
              <tr>
                <th scope="col">Editar mesa</th>
                <th scope="col">Mesa</th>
                <th scope="col">Ocupantes/Lugares</th>
                <th scope="col">Status</th>
                <th scope="col">Liberar mesa</th>
                <th scope="col">Zerar ocupantes</th>
                <th scope="col">Ocupar mesa</th>
              </tr>
            </thead>
            <tbody>
              {tables.map((item, index) => {
                return (
                  <tr key={index}>
                    <td><button className="btn-edit-table" onClick={() => toggleModalTable(item)} ><FaRegEdit size={25} color="#000" /></button></td>
                    <td data-label="Mesa">{item.identificacao}</td>
                    <td data-label="Ocupantes/Lugares">{item.ocupantes}/{item.lugares}</td>
                    <td data-label="Status">
                      <span className="badge" style={{ backgroundColor: item.status === true ? 'rgba(0, 255, 0, .9)' : 'rgba(255, 0, 0, 0.7)' }}>{item.status === true ? 'Disponível' : 'Indisponível'}</span>
                    </td>
                    <td>
                      <button className="btn-release"
                        onClick={() => clearTable(item.idTable)}>
                        <em color="#fff">Liberar </em>
                      </button>
                    </td>
                    <td>
                      <button className="btn-reset"
                        onClick={() => clearOccupants(item.idTable)}>
                        <em color="#fff">Zerar</em>
                      </button>
                    </td>
                    <td data-label="#">
                      <button className="btn-occupy" onClick={() => occupyTable(item.idTable)}>
                        <em color="#fff">Ocupar</em>
                      </button>

                    </td>
                  </tr>

                )
              })}


            </tbody>
          </table>
          {showModalTable && (
            <ModalTable
              detailTable={detailTable}
              closeModal={toggleModalTable}
            //  nextStatus={nextStatus}
            />
          )}

        </>

      </div>

    </div>
  );
}