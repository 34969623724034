
import { useState, useEffect, useContext } from 'react'
//import './customers.css';
import './newtable.css';
import Title from '../../components/Title';
import Header from '../../components/Header';
import firebase from '../../services/firebaseConnection';
import { FiUser } from 'react-icons/fi';

import { AuthContext } from '../../contexts/auth';
import { useHistory, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

export default function Testing() {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  let uid = user.uid;

  const [nomeCategoria, setNomeCategoria] = useState('');

  const [places, setPlaces] = useState('');
  const [identification, setIdentification] = useState('');

  const listRef = firebase.firestore().collection('pdvUsers');
  
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadCategorias() {




      await firebase.firestore().collection('pdvUsers')
        .get()
        .then((snapshot) => {

          console.log(snapshot);


        })
        .catch((err) => {
          console.log('Erro ao buscar: ', err);

        })

    }

    loadCategorias();

    return () => {

    }

  }, );




  async function handleAdd(e) {
    e.preventDefault();

    if (identification !== '' && places !== '') {
      await firebase.firestore().collection('pdvUsers')
        .doc(uid)
        .collection('mesas')
        .add({
          lugares: places,
          identificacao: identification,
          situacao: false,
        })
        .then(() => {
          setPlaces();
          toast.info('Mesa cadastrada com sucesso!');
          history.push('/localflow');
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro ao cadastrar mesa')
        })
    } else {
      toast.error('Preencha todos os campos!');
    }

  }



  return (
    <div>
      <Header />

      <div className="content">
        <Title name="Cadastrar mesa">
          <FiUser size={25} />
        </Title>


        <div className="container" >

          <form className="form-tables " onSubmit={handleAdd}>
            <label>Identificação da mesa</label>
            <i style={{ fontSize: 11, color: 'rgb(255,0,0)' }}>*se for abaixo de 10 utilize o "0" na frete, exemplo: 01</i>
            <input className="input-table" type="text" value={identification} placeholder="Identificação da mesa a ser criada, ex: 99"
              onChange={(e) => setIdentification(e.target.value)} />

            <label>Lugares da mesa</label>
            <input className="input-table" type="number" value={places} onChange={(e) => setPlaces(e.target.value)} />


            <button type="submit">Cadastrar mesa</button>
          </form>
        </div>
      </div>


    </div>
  );
}