import React, { useState } from 'react';
import './modal.css';
import { format, toDate } from 'date-fns';
import { FiX } from 'react-icons/fi'

import { GrChapterNext } from 'react-icons/gr';

export default function Modal({ conteudo, close, nextStatus }) {
  return (
    <div className="modal">
      <div className="container">

        <button className="close" onClick={close}>
          <FiX size={23} color="#fff" />
          Voltar
        </button>
        <div styles={{ overflowY: 'scroll' }}>


          <h2>Detalhes do pedido:</h2>
          {conteudo.status === 'check' ? (
              <div>
                <span>
                  Status: <i style={{ color: '#fff', backgroundColor: conteudo.status === 'Aberto' ? '#5cb85c' : '#999' }}>{conteudo.status === 'check' ? 'Checando' : conteudo.status}</i>
                </span>
                <button style={{ backgroundColor: 'rgba(255,0,0,0.6)', color: '#FFF', border: 1, margin: '4px', flexDirection: 'row', alignItems: 'center', padding: '2px' }} onClick={() => console.log(conteudo.status, conteudo.idRequest, conteudo.uidUserRequest)}>Recusar</button>

                <button style={{ backgroundColor: 'rgba(0,255,0,0.5)', color: '#333', border: 1, margin: '4px', flexDirection: 'row', alignItems: 'center', padding: '2px' }} onClick={() => nextStatus(conteudo.status, conteudo.idRequest, conteudo.uidUserRequest)}>Aceitar</button>
              </div>
            )
              : (
                <div style={{ justifyContent: 'center' }}>
                  <span>
                    Status: <i style={{ color: '#fff', backgroundColor: conteudo.status === 'Aberto' ? '#5cb85c' : '#999' }}>{conteudo.status}</i>
                  </span>
                  <button
                    className="btn-avancar"
                    style={{ backgroundColor: '#5cb85c', color: '#fff' }}
                    onClick={() => nextStatus(conteudo.status, conteudo.idRequest, conteudo.uidUserRequest)}>
                    AVANÇAR STATUS
                  </button>

                </div>
              )
            }



          <div className="row">
            <span>
              Cliente: <i>{conteudo.nomeCliente}</i>
            </span>
            <div id="line" />
            <p />
            <span>
              Mesa: <i>{conteudo.mesa}</i>
            </span>
            <div id="line" />
            <p />
            <span>Valor total: </span><i>R$ {parseFloat(conteudo.amount).toFixed(2)}</i>
            <span style={{ marginLeft: 20 }}>Forma de pagamento: </span>
            <i>{conteudo.methodPayment === 'card' ? 'Cartão'
              : conteudo.methodPayment === 'money' ? 'Dinheiro'
                : 'null'}</i>

            <span style={{ marginLeft: 20 }}>Troco para: R$</span>
            <i>{conteudo.changeForPayment === '' ? 'Não informado'
              : parseFloat(conteudo.changeForPayment).toFixed(2)
            }
            </i>
            <span style={{ marginLeft: 20 }}>Troco sugerido: </span><i>R$ {parseFloat(conteudo.changeForPayment - conteudo.amount).toFixed(2)} </i>
            <div id="line" />
            <p />

            {/* {
              status === 'Agendamento' ? 'Solicitar agendamento'
                : status === 'Progresso' ? 'Em progresso'
                  : status === 'Atendido' ? 'Atendido'
                    : 'error'} */}

            <span>
            Realizado em:<i>{format(toDate(conteudo.created), 'dd/MM/yyyy - HH:mm:ss')}</i> 
            </span>
            <div id="line" />
            <span></span><i></i>
            <p />
          </div>
          {/* <Section>
                    <Label>Realizado em:</Label><TextDetail>{format(toDate(data.created), 'dd/MM/yyyy - HH:mm:ss')}</TextDetail> 
                  </Section>
                  <Line />
                  <Section>
                    <Label>Finalizado em:</Label><TextDetail>{format(toDate(data.finished), 'dd/MM/yyyy - HH:mm:ss')}</TextDetail> 
                  </Section> */}
          <div className="row">

          </div>

          <div id="status-modal">

           
          </div>

          <h3>Lista de itens: ({conteudo.itens} - {conteudo.itens > 1 ? 'itens' : 'item'})</h3>

          <table id="table">
            <thead>
              <tr style={{ border: 2 }}>
                <th style={{ width: '4%', backgroundColor: '#777' }} scope="col">#</th>
                <th style={{ width: '4%', backgroundColor: '#ccc' }} scope="col">QTD</th>
                <th style={{ textAlign: 'left', width: '20%', backgroundColor: '#eee' }} scope="col">Produto</th>
                <th style={{ textAlign: 'left', width: '10%', backgroundColor: '#ccc' }} scope="col">Imagem Produto</th>
                <th style={{ textAlign: 'left', width: '66%', backgroundColor: '#eee' }} scope="col">Observação do cliente</th>
              </tr>
            </thead>
            <tbody>
              {conteudo.list.map((item, index) => {
                return (
                  <>
                    <tr key={index}>

                      <td style={{ width: '4%', backgroundColor: '#777' }} data-label="QTD">{index + 1}º</td>
                      <td style={{ width: '4%', backgroundColor: '#ccc' }} data-label="QTD">{item.qtd}</td>
                      <td style={{ textAlign: 'left', width: '20%', backgroundColor: '#eee' }} data-label="Produto">{item.nomeProduto}</td>
                      <td style={{ textAlign: 'left', width: '10%', backgroundColor: '#ccc' }} data-label="Imagem Produto"><img className="imageUrl-item-request" src={item.imageUrl} alt="Foto produto" /></td>
                      <td style={{ textAlign: 'left', width: '66%', backgroundColor: '#eee' }} data-label="Observação do cliente">{item.note}</td>
                    </tr>
                    <tr><div style={{ backgroundColor: '#000', height: 1 }}></div></tr>

                  </>
                )
              })}

            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}