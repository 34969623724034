import React, { useState, useContext } from 'react';
import './modaltable.css';
import firebase from '../../services/firebaseConnection';

import { FiX } from 'react-icons/fi'
import { toast } from 'react-toastify';

import { AuthContext } from '../../contexts/auth';

export default function ModalTable({ closeModal, detailTable }) {
  const { user } = useContext(AuthContext);

  const linkRef = firebase.firestore().collection('pdvUsers').doc(user.uid);

  const [places, setPlaces] = useState(detailTable.lugares);
  const [occupants, setOccupants] = useState(detailTable.ocupantes);
  const [identification, setIdentification] = useState(detailTable.identificacao);
  const [idTable, setIdTable] = useState(detailTable.idTable);

  async function handleEdit(e) {
    e.preventDefault();

    if (identification !== '' && places !== '') {
      await linkRef.collection('mesas').doc(idTable)
        .update({
          lugares: places,
          identificacao: identification,
          ocupantes: occupants,

        })
        .then(() => {
          setPlaces();
          toast.info('Mesa editada com sucesso!');
          // history.push('/localflow');
          closeModal();
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro ao editar mesa')
        })
    } else {
      toast.error('Preencha todos os campos!');
    }

  }

  function removeTable() {
    linkRef.collection('mesas')
      .doc(idTable).delete()
      .then(
        () => {
          toast.info('A Mesa: ' + detailTable.identificacao + ' \n ' + idTable + '\n foi deletada.')
          closeModal();
        })


  }


  return (
    <div className="modal-table-edit">

      <button className="close" onClick={closeModal}>
        <FiX size={23} color="#fff" />
        Voltar
      </button>
      <div className="container-modal">
     
        <i style={{ fontSize: 14, color: 'rgb(255,0,0)' }}>id: {idTable}</i>
        <h1>EDITAR MESA</h1>


        <label>Identificação da mesa: / Atual: "{detailTable.identificacao}"</label>
        <i style={{ fontSize: 14, color: 'rgb(255,0,0)' }}>*se for abaixo de 10 utilize o "0" na frete, exemplo: 01</i>
        <input type="text" value={identification} placeholder="Identificação da mesa a ser criada, ex: 99"
          onChange={(e) => setIdentification(e.target.value)} />

        <label>Ocupantes da mesa: (atual: {detailTable.ocupantes})</label>
        <input className="input-table" type="number" placeholder="ex: 2" value={occupants} onChange={(e) => setOccupants(e.target.value)} />

        <label>Lugares da mesa: (atual: {detailTable.lugares})</label>
        <input className="input-table" type="number" placeholder="ex: 2" value={places} onChange={(e) => setPlaces(e.target.value)} />

        <button className="btn-handleEdit" onClick={handleEdit}>Salvar edição</button>
        <div style={{marginTop: 15}}>
          <button onClick={() => removeTable()} style={{ border: 0, backgroundColor: 'rgb(255, 0, 2)', color: '#fff', padding: 4, borderRadius: 3 }}>Excluir mesa</button>
        </div>
      </div>

    </div>
  );
}
{/* <input type="text" value={identification} placeholder="Identificação da mesa a ser criada, ex: 99"
onChange={ (e) => setIdentification(e.target.value)}/> */}
