import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Customers from '../pages/Customers'
import New from '../pages/New';

import Requests from '../pages/Requests';
import HistoryRequest from '../pages/HistoryRequest';
import HistoryRequestYear from '../pages/HistoryRequest/HistoryRequestYear';
import HistoryRequestMonth from '../pages/HistoryRequest/HistoryRequestMonth';
import Categories from '../pages/Categories';
import Products from '../pages/Products';
import NewCategory from '../pages/NewCategory';
import NewProduct from '../pages/NewProduct';
import NewTable from '../pages/NewTable';
import EditProduct from '../pages/EditProduct';
import LocalFlow from '../pages/LocalFlow';
import Testing from '../pages/Testing';
import Contabil from '../pages/Contabil';
import CommandOpen from '../pages/CommandOpen';
import CommandOrders from '../pages/CommandOrders';



export default function Routes(){
  return(
    <Switch>
      <Route exact path="/" component={SignIn}/>
      <Route exact path="/register" component={SignUp} />


      <Route exact path="/requests" component={Requests} isPrivate /> {/*PEDIDOS RECEBIDOS*/}
      <Route exact path="/command-open" component={CommandOpen} isPrivate />{/*PEDIDOS RECEBIDOS EM COMANDA ABERTA*/}
      <Route exact path="/command-open/:idCommand" component={CommandOrders} isPrivate/>{/*DETALHES DE UMA COMANDA ABERTA*/}
      <Route exact path="/historyrequest" component={HistoryRequest} isPrivate />{/*DESABILITADO*/}
      <Route exact path="/historyrequestyear" component={HistoryRequestYear} isPrivate />{/*HISTORICO CLASSIFICADO POR ANO*/}
      <Route exact path="/historyrequestyear/:idYear/:idMonth" component={HistoryRequestMonth} isPrivate />{/*HISTORICO DENTRO DE UM MES E ANO SELECIONADO*/}
      
      <Route exact path="/categories" component={Categories} isPrivate />{/* //       */}
      <Route exact path="/categories/:id" component={Products} isPrivate />{/* //       */}
      <Route exact path="/newcategory" component={NewCategory} isPrivate />{/* //       */}
      <Route exact path="/newproduct" component={NewProduct} isPrivate />{/* //       */}
      <Route exact path="/newtable" component={NewTable} isPrivate />{/* //       */}
      <Route exact path="/editproduct/:idCategory/:idProduct" component={EditProduct} isPrivate />{/* //       */}
      <Route exact path="/localflow" component={LocalFlow} isPrivate />{/* //       */}
      <Route exact path="/contabil" component={Contabil} isPrivate/>{/* //       */}

      

      <Route exact path="/dashboard" component={Dashboard} isPrivate />{/* //       */}
      <Route exact path="/profile" component={Profile} isPrivate />{/* //       */}
      <Route exact path="/customers" component={Customers} isPrivate />{/* //       */}
      <Route exact path="/new" component={New} isPrivate />{/* //       */}
      <Route exact path="/new/:id" component={New} isPrivate />{/* //       */}

      <Route exact path="/testing" component={Testing} isPrivate/>{/* //       */}


    </Switch>
  )
}