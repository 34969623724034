import React, { useState, useContext } from 'react';
import './profile.css'
import Header from '../../components/Header';
import Title from '../../components/Title';
import logo from '../../assets/avatar.png';

import firebase from '../../services/firebaseConnection';
import { AuthContext } from '../../contexts/auth';

import { FiSettings, FiUpload } from 'react-icons/fi';

export default function Profile() {
  const { user, signOut, setUser, storageUser } = useContext(AuthContext)

  const [nome, setNome] = useState(user && user.nome);
  const [email, setEmail] = useState(user && user.email);
  const [cnpj, setCnpj] = useState(user && user.cnpj);
  const [logradouro, setLogradouro] = useState(user && user.logradouro);
  const [numero, setNumero] = useState(user && user.numero);
  const [bairro, setBairro] = useState(user && user.bairro);
  const [cidade, setCidade] = useState(user && user.cidade);
  const [estado, setEstado] = useState(user && user.estado);
  const [cep, setCep] = useState(user && user.cep);
  const [contato1, setContato1] = useState(user && user.contato1);
  const [contato2, setContato2] = useState(user && user.contato2);

  const [logoUrl, setLogoUrl] = useState(user && user.logoUrl);
  const [imageLogo, setImageLogo] = useState(null);
 
  function handleFile(e){
    
    if(e.target.files[0]){
      const image = e.target.files[0];

      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageLogo(image);
        setLogoUrl(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageLogo(null);
        return null;
      }
    }
    
   
  }

  async function handleUpload(){
    const currentUid = user.uid;

    const uploadTask = await firebase.storage()
    .ref(`images/${currentUid}/${imageLogo.name}`)
    .put(imageLogo)
    .then(async () => {
      console.log('Foto enviada com sucesso!');

      await firebase.storage().ref(`images/${currentUid}`)
      .child(imageLogo.name).getDownloadURL()
      .then(async (url) => {
        let urlFoto = url;

        await firebase.firestore().collection('pdvUsers')
        .doc(user.uid)
        .update({
          logoUrl: urlFoto,
          nome: nome,
          nomeUpperCase: nome.toUpperCase(),
          cidade: cidade,
          cidadeUpperCase: cidade.toUpperCase(),
          logradouro: logradouro,
          numero: numero, 
          bairro: bairro,
          estado: estado,
          cep: cep, 
          contato1: contato1,
          contato2: contato2
        })
        .then( () => {
          let data = {
            ...user,
            logoUrl: urlFoto,
            nome: nome,
            nomeUpperCase: nome.toUpperCase(),
            cidade: cidade,
            cidadeUpperCase: cidade.toUpperCase(),
            logradouro: logradouro,
            numero: numero, 
            bairro: bairro,
            estado: estado,
            cep: cep, 
            contato1: contato1,
            contato2: contato2
          };
          setUser(data);
          storageUser(data);
          
        })
      })

    })

  }

  async function handleSave(e){
    e.preventDefault();
   
    if(imageLogo === null 
      && nome !== '' 
      && logradouro !== ''
      && numero !== ''
      && bairro !== ''
      && cidade !== ''
      && estado !== ''
      && cep !== ''
      && contato1 !== ''
      && contato2 !== ''){

        await firebase.firestore().collection('pdvUsers')
        .doc(user.uid)
        .update({
          nome: nome,
          nomeUpperCase: nome.toUpperCase(),
          cidade: cidade,
          cidadeUpperCase: cidade.toUpperCase(),
          logradouro: logradouro,
          numero: numero, 
          bairro: bairro,
          estado: estado,
          cep: cep, 
          contato1: contato1,
          contato2: contato2
        })
        .then(()=>{
          let data = {
            ...user,
            nome: nome ,
            logradouro: logradouro,
            numero: numero, 
            bairro: bairro,
            cidade: cidade,
            estado: estado,
            cep: cep, 
            contato1: contato1,
            contato2: contato2
          };
          setUser(data);
          storageUser(data);
        })
        await firebase.firestore().collection('pdvUsers')
        .doc(user.uid)
        .collection('cadastro')
        .doc(user.uid)
        .update({
          nome: nome,
          nomeUpperCase: nome.toUpperCase(),
          cidade: cidade,
          cidadeUpperCase: cidade.toUpperCase(),
          logradouro: logradouro,
          numero: numero, 
          bairro: bairro,
          estado: estado,
          cep: cep, 
          contato1: contato1,
          contato2: contato2
        })
        .then(()=>{
          let data = {
            ...user,
            nome: nome ,
            logradouro: logradouro,
            numero: numero, 
            bairro: bairro,
            cidade: cidade,
            estado: estado,
            cep: cep, 
            contato1: contato1,
            contato2: contato2
          };
          setUser(data);
          storageUser(data);
        })
    }
    else if(imageLogo !== null 
      && nome !== '' 
      && logradouro !== ''
      && numero !== ''
      && bairro !== ''
      && cidade !== ''
      && estado !== ''
      && cep !== ''
      && contato1 !== ''
      && contato2 !== ''){
        
        handleUpload();
      }


  }


 return (
   <div>
     <Header/>

      <div className="content">
        <Title name="Meu perfil">
          <FiSettings size={25}/>
        </Title>
      

        <div className="container">
          <form className="form-profile" onSubmit={handleSave}>
         

            <label>Nome do estabelecimento</label>  
            <input type="text" value={nome} onChange={ (e) => setNome(e.target.value) }/>

            <label>Email</label>  
            <input type="text" value={email} disabled={true}/>

            <label>CNPJ</label>  
            <input type="text" value={cnpj} disabled={true}/>

            <label>Logradouro</label>  
            <input type="text" value={logradouro} onChange={ (e) => setLogradouro(e.target.value) }/>

            <label>Número</label>  
            <input type="text" value={numero} onChange={ (e) => setNumero(e.target.value) }/>

            <label>Bairro</label>  
            <input type="text" value={bairro} onChange={ (e) => setBairro(e.target.value) }/>

            <label>Cidade</label>  
            <input type="text" value={cidade} onChange={ (e) => setCidade(e.target.value) }/>

            <label>Estado</label>  
            <input type="text" value={estado} onChange={ (e) => setEstado(e.target.value) }/>

            <label>CEP</label>  
            <input type="text" value={cep} onChange={ (e) => setCep(e.target.value) }/>

            <label>Contato 1</label>  
            <input type="text" value={contato1} onChange={ (e) => setContato1(e.target.value) }/>

            <label>Contato 2</label>  
            <input type="text" value={contato2} onChange={ (e) => setContato2(e.target.value) }/>


            <label className="label-avatar">
              <span>
                <FiUpload color="#fff" size={25}/>
              </span>

              <input type="file" accept="image/*" onChange={handleFile}/><br/>
              { logoUrl === null ?
                <img src={logo} width="250" height="250" alt="Foto logo"/> 
                :
                <img src={logoUrl} width="250" height="250" alt="Foto logoa"/> 
              }
            </label>

            <button type="submit">Salvar</button>


          </form>
        </div>
        
        <div className="container">
              <button className="logout-btn" onClick={ () => signOut() }>
                Sair
              </button>
        </div>

      </div>
   </div>
 );
}