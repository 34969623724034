import './contabil.css'
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';

import { MdRestaurantMenu, MdFirstPage } from 'react-icons/md';
import { BiRefresh } from 'react-icons/bi';

import { GrMoney } from 'react-icons/gr'

import { AuthContext } from '../../contexts/auth';
import firebase from '../../services/firebaseConnection';

import { format, milliseconds, millisecondsToHours } from 'date-fns'

export default function Contabil() {


  const { user, toFileRequest } = useContext(AuthContext);
  const [id, setId] = useState(user.uid)


  const linkRefRequestsPrevious = firebase.firestore().collection('pdvUsers').doc(id).collection('requestsPrevious');
  const linkRefRequestsProgress = firebase.firestore().collection('pdvUsers').doc(id).collection('requestsProgress');

  const [dataProgress, setDataProgress] = useState([]);//carrega pedidos em andamento
  const [dataPrevious, setDataPrevious] = useState([]);//carrega pedidos arquivados

  const [faturamento_bruto_total, set_faturamento_bruto_total] = useState(0);
  const [faturamento_bruto_progress, set_faturamento_bruto_progress] = useState(0);
  const [faturamento_bruto_previous, set_faturamento_bruto_previous] = useState(0);
  const [qtd_vendas_total, set_qtd_vendas_total] = useState(0);
  const [qtd_vendas_progress, set_qtd_vendas_progress] = useState(0);
  const [qtd_vendas_previous, set_qtd_vendas_previous] = useState(0);
  const [qtd_itens_vendidos_total, set_qtd_itens_vendidos_total] = useState(0);
  const [qtd_itens_vendidos_progress, set_qtd_itens_vendidos_progress] = useState(0);
  const [qtd_itens_vendidos_previous, set_qtd_itens_vendidos_previous] = useState(0);
  const [qtd_recusas_valor, setQtd_recusas_valor] = useState(0);
  const [qtd_recusas, setQtd_recusas] = useState(0);
  const [qtd_recusas_itens, setQtd_recusas_itens] = useState(0);
  const [l_faturamento_total_bruto, setL_faturamento_total_bruto] = useState(0);
  const [lastUpdate, setLastUpdate] = useState('')

  const [monthSelected, setMonthSelected] = useState('month01');
  const [yearSelected, setYearSelected] = useState('year2022');

  function handleChangeMonth(e) {
    setMonthSelected(e.target.value);
  }
  function handleChangeYear(e) {
    setYearSelected(e.target.value);
  }
  async function somar() {
    var faturamento_total = 0;
    var faturamento_progress = 0;
    var faturamento_previous = 0;
    // var qtd_vendas_total = 0;
    // var qtd_vendas_progress = 0;
    // var qtd_vendas_previous = 0;
    var recusas_valor = 0;
    var recusas_qtd = 0;
    var recusas_qtd_itens = 0;

    for (var i = 0; i < dataProgress.length; i++) {
      //set_qtd_vendas_progress(dataProgress.length)
      if (dataProgress[i].status === 'PEDIDO RECUSADO') {
        recusas_valor += dataProgress[i].valor;
        recusas_qtd += 1;
        recusas_qtd_itens += dataProgress[i].itens;
      } else {
        faturamento_progress += dataProgress[i].valor;
      }
    }
    set_qtd_vendas_progress(dataProgress.length - recusas_qtd )
    for (var i = 0; i < dataPrevious.length; i++) {
      set_qtd_vendas_previous(dataPrevious.length)
      if (dataPrevious[i].status === 'PEDIDO RECUSADO') {
        recusas_valor += dataPrevious[i].valor;
        recusas_qtd += 1;
        recusas_qtd_itens += dataPrevious[i].itens;
      } else {
        faturamento_previous += dataPrevious[i].valor;
      }
    }

    set_faturamento_bruto_previous(faturamento_previous);
    set_faturamento_bruto_progress(faturamento_progress);
    set_faturamento_bruto_total(faturamento_previous + faturamento_progress);
    set_qtd_vendas_total(qtd_vendas_progress + qtd_vendas_previous);

    //    setL_faturamento_total_bruto(total * .2);
    setQtd_recusas(recusas_qtd);
    setQtd_recusas_itens(recusas_qtd_itens);
    setQtd_recusas_valor(recusas_valor);
    // console.log(total);
    // console.log(total * .2);
    // console.log(recusas_qtd);
    // console.log(recusas_qtd_itens);
    // console.log(recusas_valor);
  }
  async function somarItens() {
    var totalItens = 0;
    var qtd_itens_previous = 0;
    var qtd_itens_progress = 0;


    for (var i = 0; i < dataProgress.length; i++) {
      qtd_itens_progress += dataProgress[i].itens;
    }
    for (var i = 0; i < dataPrevious.length; i++) {
      qtd_itens_previous += dataPrevious[i].itens;
    }
    set_qtd_itens_vendidos_previous(qtd_itens_previous);
    set_qtd_itens_vendidos_progress(qtd_itens_progress - qtd_recusas_itens);
    set_qtd_itens_vendidos_total(qtd_itens_previous + qtd_itens_progress - qtd_recusas_itens);
  }




  async function carregarInfoPedidos() {
    await linkRefRequestsPrevious.doc(yearSelected).collection(monthSelected).get().then((doc) => {
      let listDataPrevious = [];
      doc.forEach((item) => {
        listDataPrevious.push({
          id: item.id,
          valor: item.data().amount,
          itens: item.data().itens,
          status: item.data().status
        })
      });
      setDataPrevious(listDataPrevious);

    })
    // await linkRefRequestsProgress.get().then((doc) => {
     const dbRequestsRef = firebase.database().ref(`pdvs/${user.uid}/requests`)
    //ONCE para atualizar cada vez q atualiza a pag e ON para tempo real
    dbRequestsRef
    .orderByChild('created')
    .once('value', (snapshot) => {
      setDataProgress([]);

      snapshot?.forEach((item) => {
        let data = {
      
          idRequest: item.key,
          status: item.val().status,
          itens: item.val().itens,
          valor: item.val().amount,

        }

        setDataProgress(oldRequests => [...oldRequests, data])
        
      })

    })

    refreshData();

  }





  async function refreshData() {
    somar();
    somarItens();
  }

  return (
    <div>
      <Header />


      <div className="content">
        <Title name={'Contábil '} >
          <GrMoney size={25} />

        </Title>

        <div id="container">
          <section style={{ flexDirection: 'row' }} >

            <div id="btn-back">
              <Link to={`/requests`}>
                <MdFirstPage size={25} />
                <i>Voltar</i>
              </Link>
            </div>

            <div id="btn-update">
              <select name="months" id="months" value={monthSelected} onChange={handleChangeMonth}>
                <option value={'month01'}>Janeiro</option>
                <option value={'month02'}>Fevereiro</option>
                <option value={'month03'}>Março</option>
                <option value={'month04'}>Abril</option>
                <option value={'month05'}>Maio</option>
                <option value={'month06'}>Junho</option>
                <option value={'month07'}>Julho</option>
                <option value={'month08'}>Agosto</option>
                <option value={'month09'}>Setembro</option>
                <option value={'month10'}>Outubro</option>
                <option value={'month11'}>Novembro</option>
                <option value={'month12'}>Dezembro</option>
              </select>
              <select name="years" id="months" value={yearSelected} onChange={handleChangeYear}>
                <option value={'year2022'}>2022</option>
                <option value={'year2023'}>2023</option>
                <option value={'year2024'}>2024</option>
              </select>
              <div id="btn">
                <button style={{ backgroundColor: 'rgba(0,0,0,0)' }} onClick={() => carregarInfoPedidos()}>
                  <BiRefresh size={25} />
                  <i>Atualizar</i>
                </button>
              </div>
            </div>
          </section>
          <div>

          </div>
          <div class="container__column">
            <h1>Vendas</h1>
            <table>
              <tr>
                <th>Variaveis</th>
                <th>Valores</th>
              </tr>
              {/* dados totais */}
              <tr>
                <th colspan="2">Total</th>
              </tr>
              <tr>
                <td>Faturamento total bruto</td>
                <td>R${parseFloat(faturamento_bruto_total).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Qtd. de Vendas</td>
                <td>{qtd_vendas_total}</td>
              </tr>
              <tr>
                <td>Qtd. de itens Vendidos</td>
                <td>{qtd_itens_vendidos_total}</td>
              </tr>
              <tr>
                <td>Qtd. em valor de recusas</td>
                <td>R${parseFloat(qtd_recusas_valor).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Qtd. de recusas</td>
                <td>{qtd_recusas}</td>
              </tr>
              <tr>
                <td>Qtd. de itens em recusas</td>
                <td>{qtd_recusas_itens}</td>
              </tr>
              {/* dados em progresso */}
              <tr>
                <th colspan="2">Em progresso</th>
              </tr>

              <tr>
                <td>Faturamento total bruto</td>
                <td>R${parseFloat(faturamento_bruto_progress).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Qtd. de Vendas</td>
                <td>{qtd_vendas_progress}</td>
              </tr>
              <tr>
                <td>Qtd. de itens Vendidos</td>
                <td>{qtd_itens_vendidos_progress}</td>
              </tr>
              <tr>
                <td>Qtd. de recusas</td>
                <td>{qtd_recusas}</td>
              </tr>
              <tr>
                <td>Qtd. em valor de recusas</td>
                <td>R${parseFloat(qtd_recusas_valor).toFixed(2)}</td>
              </tr>
              {/* dados finalizados */}
              <tr>
                <th colspan="2">Finalizados</th>
              </tr>
              <tr>
                <td>Faturamento total bruto</td>
                <td>R${parseFloat(faturamento_bruto_previous).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Qtd. de Vendas</td>
                <td>{qtd_vendas_previous}</td>
              </tr>
              <tr>
                <td>Qtd. de itens Vendidos</td>
                <td>{qtd_itens_vendidos_previous}</td>
              </tr>
              <tr>
                <td>Qtd. em valor de recusas</td>
                <td>R${parseFloat(qtd_recusas_valor).toFixed(2)}</td>
              </tr>

            </table>

          
          </div>
          



        </div>

      </div>
    </div>
  );
}