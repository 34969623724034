import React from 'react';
import './title.css';

import { Link } from 'react-router-dom';
import { GrHistory } from "react-icons/gr";

export default function Title({children, name}) {
 return (

   <div className="title">
   
     {children}
     <span>{name}</span>

   </div>

 );
}