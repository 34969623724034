import React, { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify'
import { format } from 'date-fns';
import '../New/new.css';
import { FiSettings } from 'react-icons/fi'
import { setWeekYear } from 'date-fns';
import { set } from 'date-fns/esm';
import id from 'date-fns/esm/locale/id/index.js';

import { confirmAlert } from 'react-confirm-alert'; // Import


export default function EditProduct() {
  const { idCategory, idProduct } = useParams();
  const history = useHistory();

  const [loadCategories, setLoadCategories] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState(0);
  const [productSelected, setProductSelected] = useState(0);
  const [linkRef, setLinkRef] = useState('');

  const [nomeProduto, setNomeProduto] = useState('');
  const [valor, setValor] = useState(0);
  const [complemento, setComplemento] = useState('');
  const [created, setCreated] = useState(0);
  const [edited, setEdited] = useState(0);

  const [idCategoryTrueOrFalse, setIdCategoryTrueOrFalse] = useState(false);
  const [keyCategory, setKeyCategory] = useState('');

  const [statusProduct, setStatusProduct] = useState();//observar

  const { user } = useContext(AuthContext);
  

  let uid = user.uid;


  useEffect(() => {
    async function loadEditing() {
      if (idCategory && idProduct) {

        let listRef = firebase.firestore().collection('pdvUsers').doc(uid).collection('categorias').doc(idCategory).collection('produtos').doc(idProduct);
        setIdCategoryTrueOrFalse(true);
        setCategorySelected(idCategory);
        setProductSelected(idProduct);
        setLinkRef(listRef);
        loadId(listRef);
      }
    }
    loadEditing();
  }, [idCategory, idProduct]);

  async function loadId(listRef) {
    await listRef.get()
      .then((snapshot) => {
        setNomeProduto(snapshot.data().nomeProduto);
        setValor(snapshot.data().valor);
        setComplemento(snapshot.data().complemento);
        setStatusProduct(snapshot.data().statusProduct);//verificar pois é nova função//observar
        setCreated(format(snapshot.data().created.toDate(), 'dd/MM/yyyy - HH:mm:s'));
        setEdited(format(snapshot.data().edited.toDate(), 'dd/MM/yyyy - HH:mm:s'));


      })
      .catch((err) => {
        console.log('Erro no ID passado: ', err);
        //  setIdCategory(false);
      })
  }

  async function handleRegister(e) {
    e.preventDefault();
    if(statusProduct === '') return; 
    if (idCategory && idProduct) {
      await linkRef.update({
        edited: new Date(),
        nomeProduto: nomeProduto,
        valor: valor,
        statusProduct,
        complemento: complemento,
      })
        .then(() => {
          toast.success('Editado com sucesso!');
          setNomeProduto('');
          setValor(0);
          setStatusProduct('');
          setComplemento('');
          history.push(`/categories/${idCategory}`);
        })
        .catch((error) => {
          toast.error('Ops erro ao tentar editar, tente mais tarde.')
          console.log('erro fi', error);
        })
    }



  }

  //func para que o valor fique em boolean
  function handleStatusProdutct(status) {
    if(status === 'true'){
      setStatusProduct(true);
    }else if(status === 'false'){
      setStatusProduct(false);
    }else(alert(status))
  }

  async function handleDeleteProduct(idProduct, idCategory) {
    confirmAlert({
      title: 'Deseja excluir este produto',
      message: 'uma vez feito isso, não será possivel reverter.',
      buttons: [
        {
          label: 'Sim',
          onClick: () =>
          firebase.firestore().collection('pdvUsers').doc(uid).collection('categorias').doc(idCategory).collection('produtos').doc(idProduct).delete().then(() => {
           toast.success('Produto excluida.');
           console.log("Document successfully deleted!");
            
          }).catch((error) => {
           toast.error('Erro ao excluir produto.')
            console.error("Error removing document: ", error);
          })
        },
        {
          label: 'Não',
          onClick: () => ({})
        }
      ]
    });

 }


  //chamado quando troca de categoria
  function handleChangeCategories(e) {
    console.log('index selecionado: ', e.target.value);
    console.log('categoria selecionada ', categories[e.target.value]);
    setCategorySelected(e.target.value);
    setKeyCategory(categories[e.target.value]);// aqui salva a chave de acesso da categoria selecionada
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Editar produto">
          <FiSettings size={25} />
        </Title>


        <div className="container">

          <form className="form-profile" onSubmit={handleRegister}>


            <label>Nome do produto</label>
            <input type="text" value={nomeProduto} onChange={(e) => setNomeProduto(e.target.value)} />

            <label>Valor do produto</label>
            <input type="number" value={valor} onChange={(e) => setValor(e.target.value)} />


            <label>Complemento</label>
            <textarea
              type="text"
              placeholder="Descreva o produto (opcional)."
              value={complemento}
              onChange={(e) => setComplemento(e.target.value)}
            />

            <br /><span>Clique para alterar o status do produto:</span>
            <select style={{ padding: '0', fontSize: '18px'}} value={statusProduct} onChange={(e) => 
               handleStatusProdutct(e.target.value)}
              >
            <option>Selecione um status...</option>
              <option value={true}>Ativar produto</option>
              <option value={false}>Desativar produto</option>
            </select>
  

              <button onClick={()=>handleDeleteProduct(idProduct, idCategory)}>Deletar produto</button>

            <i style={{ fontWeight: '500' }}>Criado em: {created}</i>

            {created === edited ?
              <i style={{ fontWeight: '600' }}>Ultima atualização: Ainda não foi editado </i>
              :
              <i style={{ fontWeight: '600' }}>Ultima atualização:{edited} </i>
            }



            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>

    </div>
  );
}