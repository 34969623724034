
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
//import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import logo from '../../assets/logo-escrita.png';

function SignUp() {
  const [nomePdv, setNomePdv] = useState('');
  const [email, setEmail] = useState('');
  const [cnpj, setCnpj] = useState('');
 //const [mask, setMask] = useState('');
  const [password, setPassword] = useState('');

  const { signUp, loadingAuth } = useContext(AuthContext);

  function handleSubmit(e){
    e.preventDefault();
    if(nomePdv !== '' && email !== '' && password !== '' ){
      signUp(email, password, nomePdv, cnpj);
    
    }
  }

  return (
    <div className="container-center">
      <div className="login">
        <div className="login-area">
          <img src={logo} alt="Logo Leeats" className="logo-login"/>
        </div>

        <form onSubmit={handleSubmit}>
          <h1>Cadastrar</h1>
          <input type="text" placeholder="Nome do estabelecimento" value={nomePdv} onChange={(e) => setNomePdv(e.target.value)}/>
          <input type="text" placeholder="email@email.com" value={email} onChange={ (e) => setEmail(e.target.value) }/>
          {/* <CpfCnpj
            className="customizedInput"
            placeholder="Digite o CNPJ do seu estabelecimento"
            type="tel"
            value={cnpj}
            onChange={(e, type) => {
              setCnpj(e.target.value);
              setMask(type === "CNPJ");
            }}
          /> */}
          <input type="password" placeholder="*******" value={password} onChange={(e) => setPassword(e.target.value) } />
          <button type="submit">{loadingAuth ? 'Carregando...' : 'Cadastrar' }</button>
        </form>  

        <Link to="/">Já tenho uma conta</Link>
      </div>
    </div>
  );
}

export default SignUp;
