
import '../Dashboard/dashboard.css';
import './requests.css';
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../contexts/auth';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { VscFileSubmodule } from 'react-icons/vsc';
import { GrHistory } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { format, toDate } from 'date-fns';

import { MdCreateNewFolder, MdRestaurantMenu } from 'react-icons/md';
import { GrChapterNext } from 'react-icons/gr';
import { GoUnverified } from 'react-icons/go';
import { RiCheckFill, RiCheckDoubleFill } from 'react-icons/ri'

import firebase from '../../services/firebaseConnection';
import Modal from '../../components/Modal';
import RefuseModal from '../../components/RefuseModal';
import { toast } from 'react-toastify';


export default function Request() {

  const [valor, setValor] = useState(0);

  let date = new Date();


  const { user, toFileRequest, loadingToFile, setLoadingToFile } = useContext(AuthContext);

  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showRefuseModal, setShowRefuseModal] = useState(false);
  const [detail, setDetail] = useState();
  const listRef = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsProgress').orderBy('created', 'desc');
  const [monthToFile, setMonthToFile] = useState(date.getMonth() + 1);
  const [yearToFile, setYearToFile] = useState(date.getFullYear());
  const strMonthToFile = monthToFile.toString().padStart(2, '0');
  //const [strMonthToFile, setStrMonthToFile] = useState(monthToFile.toString())
  const [filterChecking, setFilterChecking] = useState(true);
  const [filterProgress, setFilterProgress] = useState(true);
  const [filterFinished, setFilterFinished] = useState(true);
  const [filterRefused, setFilterRefused] = useState(false);
  const [ticket, setTicket] = useState(0);
  const [lastTicket, setLastTicket] = useState(0)

  const [newDate, setNewDate] = useState(new Date());

  const lastTicketRef = firebase.database().ref(`pdvs/${user.uid}/lastTicket`);


  //let requests = database().ref('requests').child(idPdvCommand);
  useEffect(() => {
    lastTicketRef.on('value', (snapshot) => {
      setLastTicket(snapshot.val());
      console.log('ultimo:'+snapshot.val())
    });
    function getRequests() {
      const dbRequestsRef = firebase.database().ref(`pdvs/${user.uid}/requests`)
      //ONCE para atualizar cada vez q atualiza a pag e ON para tempo real
      dbRequestsRef
        .orderByChild('created')
        .on('value', (snapshot) => {
          setRequests([]);

          snapshot?.forEach((item) => {
            let data = {
              // key: childItem.key,
              // nome: childItem.val().nome
              idRequest: item.key,
              nomeCliente: item.val().nomeCliente,
              status: item.val().status,
              created: item.val().created,
              createdFormated: format(toDate(item.val().created), 'dd/MM/yyyy - HH:mm:s'),
              finished: item.val().finished,
              //date: item.val().date,
              // createdDateHours: item.val().createdDateHours,
              // createdDateDay: item.val().createdDateDay,
              // createdFormated: ),
              // monthToFile: format(item.val().created.toDate(), 'MM'),
              // yearToFile: format(item.val().created.toDate(), 'yyyy'),
              itens: item.val().itens,
              list: item.val().list,
              mesa: item.val().mesa,
              uidUserRequest: item.val().uidUserRequest,
              amount: item.val().amount,
              changeForPayment: item.val().changeForPayment,
              methodPayment: item.val().methodPayment,
              uidPdv: user.uid,
              pdvNome: item.val().pdvNome,
              pdvLogo: item.val().pdvLogo,
              pdvRua: item.val().pdvRua,
              pdvCidade: item.val().pdvCidade,
              pdvEstado: item.val().pdvEstado,
              ticket: item.val().ticket

            }

            setRequests(oldRequests => [...oldRequests, data])
            setLoading(false);
          })

        })

    }


    getRequests();


  }, []);

  // useEffect(() => {

  //   async function loadRequests() {
  //     await listRef.onSnapshot((doc) => {
  //       let listRequests = [];
  //       doc.forEach((item) => {
  //         listRequests.push({
  //           idRequest: item.id,
  //           nomeCliente: item.data().nomeCliente,
  //           status: item.data().status,
  //           created: item.data().created,
  //           createdFormated: format(item.data().created.toDate(), 'dd/MM - HH:mm:s'),
  //           monthToFile: format(item.data().created.toDate(), 'MM'),
  //           yearToFile: format(item.data().created.toDate(), 'yyyy'),
  //           itens: item.data().itens,
  //           listaPedidos: item.data().list,
  //           mesa: item.data().mesa,
  //           uidUserRequest: item.data().uidUserRequest,
  //           amount: item.data().amount,
  //           changeForPayment: item.data().changeForPayment,
  //           methodPayment: item.data().methodPayment,
  //           uidPdv: user.uid,
  //           pdvNome: item.data().pdvNome,
  //           pdvLogo: item.data().pdvLogo,
  //           pdvRua: item.data().pdvRua,
  //           pdvCidade: item.data().pdvCidade,
  //           pdvEstado: item.data().pdvEstado,

  //         })


  //       });


  //       setRequests(listRequests);
  //       setLoading(false);

  //     })


  //   }

  //   loadRequests();

  // }, []);



  async function accept(statusMomment, idRequest, uidUserRequest) {
    nextStatus(statusMomment, idRequest, uidUserRequest);
  }

  async function refuse(statusMomment, idRequest, uidUserRequest, reasonRefuse, observationsRefuse, observationsClientRefuse) {
    console.log(idRequest)
    const dbRequestRef = firebase.database().ref(`pdvs/${user.uid}/requests`).child(idRequest);
    if (statusMomment === 'check') {
      dbRequestRef.update({ status: 'PEDIDO RECUSADO', reasonRefuse, observationsRefuse, observationsClientRefuse })
      togglePostModal();

    } else if (statusMomment === 'Em andamento') {

      console.log('000')
    }

    // let linkRefStatusRequestPdv = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsProgress').doc(idRequest);
    // let linkRefStatusRequestUser = firebase.firestore().collection('users').doc(uidUserRequest).collection('requestsProgress').doc(idRequest);
    // let linkRefStatusMonitoring = firebase.firestore().collection('monitoringSystem').doc('pdvUsers').collection(user.uid).doc('pedidos').collection('requestsProgress').doc(idRequest);

    // if (statusMomment === 'check') {

    //   await linkRefStatusRequestPdv.update({ status: 'PEDIDO RECUSADO', reasonRefuse, observationsRefuse, observationsClientRefuse });
    //   await linkRefStatusRequestUser.update({ status: 'PEDIDO RECUSADO', observationsClientRefuse });
    //   await linkRefStatusMonitoring.update({ status: 'PEDIDO RECUSADO', reasonRefuse, observationsRefuse, observationsClientRefuse });

    //   if (showPostModal === true) {
    //     togglePostModal();
    //   }


    // }
    // setShowRefuseModal(false);
  }

  async function nextStatus(statusMomment, idRequest, uidUserRequest, amount) {
    console.log(idRequest)
    let newTicket = lastTicket + 1;
    const dbRequestRef = firebase.database().ref(`pdvs/${user.uid}/requests`).child(idRequest);
    if (statusMomment === 'check') {

      dbRequestRef.update({ status: 'Em andamento', ticket: newTicket })
      firebase.database().ref(`pdvs/${user.uid}`).update({lastTicket: newTicket})

      if (showPostModal === true) {
        togglePostModal();
      }


    } else if (statusMomment === 'Em andamento') {

      if (showPostModal === true) {
        togglePostModal();
      }
      dbRequestRef.update({ status: 'Finalizado', finished: new Date().getTime(), finishedDateDay: format(new Date(), 'dd/MM/yyyy'), finishedDateHours: format(new Date(), 'HH:mm:ss') })

    }//este ultimo if é apenas para teste, apagar posteriormente
    else if (statusMomment === 'Finalizado') {

      if (showPostModal === true) {
        togglePostModal();
      }
      alert('Pedido já está finalizado, o ultimo passso é arquivar.')
      // dbRequestRef.update({ status: 'check' })
    }
  }


  async function setTicketRequest(idRequest, ticket) {
    firebase.database().ref(`pdvs/${user.uid}`).update({lastTicket: 3})


    // const dbRequestRef = firebase.database().ref(`pdvs/${user.uid}/requests`).child(idRequest);
    // dbRequestRef.update({ ticket: ticket })
    // setTicket('')

  }


  //editar status
  async function STOPnextStatus(statusMomment, idRequest, uidUserRequest, amount) {
    let linkRefStatusRequestPdv = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsProgress').doc(idRequest);
    let linkRefStatusRequestUser = firebase.firestore().collection('users').doc(uidUserRequest).collection('requestsProgress').doc(idRequest);
    let linkRefStatusMonitoring = firebase.firestore().collection('monitoringSystem').doc('pdvUsers').collection(user.uid).doc('pedidos').collection('requestsProgress').doc(idRequest);
    // firebase.firestore().collection('monitoringSystem').doc('pdvUsers').collection(user.uid).doc('contabil').collection('faturamento').doc('totais').onSnapshot((doc) => {
    //   setValor(doc.data().total_em_vendas)
    // });
    if (statusMomment === 'check') {
      await linkRefStatusRequestPdv.update({ status: 'Em andamento' });
      await linkRefStatusRequestUser.update({ status: 'Em andamento' });
      await linkRefStatusMonitoring.update({ status: 'Em andamento' });
      if (showPostModal === true) {
        togglePostModal();
      }

    } else if (statusMomment === 'Em andamento') {
      let newValor = valor + amount;
      if (showPostModal === true) {
        togglePostModal();
      }
      await linkRefStatusRequestPdv.update({ status: 'Finalizado', finished: new Date() });
      await linkRefStatusRequestUser.update({ status: 'Finalizado', finished: new Date() });
      await linkRefStatusMonitoring.update({ status: 'Finalizado', finished: new Date() });
      // await firebase.firestore().collection('monitoringSystem')
      // .doc('pdvUsers').collection(user.uid)
      // .doc('contabil').collection('faturamento')
      // .doc('totais')
      // .update({ total_em_vendas: (parseFloat(valor) + parseFloat(amount)) });
    }



  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal) // trocando de true para false
    setDetail(item);

  }

  //abre modal para passar confirmações de cancelamento
  function toggleRefuseModal(item) {
    setShowRefuseModal(!showRefuseModal) // trocando de true para false
    setDetail(item);

  }

  //item, user.uid, monthToFile, yearToFile
  //item, uidPdv, monthToFile, yearToFile
  function toFile(item, uidPdv) {
    if (item.status === 'Finalizado') {
      //   console.log(strMonthToFile)
      toFileRequest(item, uidPdv, strMonthToFile, yearToFile)
      //  setLoadingToFile(true);
    } else { toast.error('Só é permitido arquivar quando o pedido estiver finalizado.') }

  }

  // setInterval(() => console.log('oi'), 10000);


  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Pedidos">
            <FiMessageSquare size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando pedidos...</span>
          </div>

        </div>
      </div>
    )
  }


  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Pedidos">
          <MdRestaurantMenu size={25} />
        </Title>
        <text>Ultimo ticket: {lastTicket}</text>
        <div className="button-history-requests">
          {/* <Link to="/historyrequest"> */}
          <Link to="/historyrequestyear">
            <i>Histórico de pedidos</i>
            <GrHistory color="#000" size="24" />
          </Link>
        </div>
        <div id="area-btn-filter">
          <button style={{ borderColor: filterChecking === true ? '#06FF00' : '' }} id="btn-filter" onClick={() => setFilterChecking(!filterChecking)}> <GoUnverified color="#000" size="15" /> <span> Checando</span></button>
          <button style={{ borderColor: filterProgress === true ? '#06FF00' : '' }} id="btn-filter" onClick={() => setFilterProgress(!filterProgress)}> <RiCheckFill color="#000" size="15" /><span> Em andamento</span></button>
          <button style={{ borderColor: filterFinished === true ? '#06FF00' : '' }} id="btn-filter" onClick={() => setFilterFinished(!filterFinished)}> <RiCheckDoubleFill color="#000" size="15" /> <span>Finalizado</span></button>
          <button style={{ borderColor: filterRefused === true ? '#06FF00' : '' }} id="btn-filter" onClick={() => setFilterRefused(!filterRefused)}> <RiCheckDoubleFill color="#000" size="15" /> <span>Recusado</span></button>

        </div>
        {requests.length === 0 ? (

          <div className="container dashboard">
            <span>Nenhum pedido registrado...</span>
          </div>

        ) : (
          <>

            <table>
              <thead>
                <tr>
                  <th scope="col">Cliente</th>
                  <th scope="col">Itens</th>
                  <th scope="col">Status</th>
                  <th scope="col">#</th>
                  <th scope="col">Ticket</th>
                  <th scope="col">Arquivar em:<br />
                    Mes:<input type="text" style={{ width: '20px', fontSize: '12px', borderRadius: 2, border: 0, padding: 1, margin: 1 }} value={monthToFile} onChange={(e) => setMonthToFile(e.target.value)} />
                    Ano:<input type="text" style={{ width: '30px', fontSize: '12px', borderRadius: 2, border: 0, padding: 1, margin: 1 }} value={yearToFile} onChange={(e) => setYearToFile(e.target.value)} />
                  </th>
                  <th scope="col">Momento do pedido</th>
                  <th scope="col">#</th>

                </tr>
              </thead>
              <tbody>
                {/* {requests.map((item, index) => { */}
                {requests.filter(item => item.status === (filterChecking === true ? 'check' : '')
                  || item.status === (filterProgress === true ? 'Em andamento' : '')
                  || item.status === (filterFinished === true ? 'Finalizado' : '')
                  || item.status === (filterRefused === true ? 'PEDIDO RECUSADO' : ''))
                  .map((item, index, array) => {
                    return (
                      <tr key={index}>
                        <td data-label="Cliente">{item.nomeCliente}</td>
                        <td data-label="Assunto">{item.itens}</td>
                        <td data-label="Status">
                          {item.status === 'check' ? (
                            <div>
                              <span className="badge" style={{ backgroundColor: '#ff0', color: '#000', fontWeight: 'normal', }}>Checando</span>

                            </div>
                          )
                            : (<div style={{ justifyContent: 'center' }}>
                              <span className="badge" style={{ backgroundColor: item.status === 'Finalizado' ? '#5cb85c' : item.status === 'PEDIDO RECUSADO' ? '#f00f' : '#e58711' }}>{item.status}</span>

                            </div>
                            )
                          }

                        </td>
                        <td>
                          {item.status === 'check' ? (
                            <div>

                              <button
                                style={{
                                  backgroundColor: 'rgba(255,0,0,0.6)',
                                  color: '#FFF',
                                  border: 1,
                                  margin: '4px',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  padding: '2px'
                                }}
                                onClick={() => toggleRefuseModal(item)}>Recusar</button>

                              <button style={{ backgroundColor: 'rgba(0,255,0,0.5)', color: '#333', border: 1, margin: '4px', flexDirection: 'row', alignItems: 'center', padding: '2px' }} onClick={() => accept(item.status, item.idRequest, item.uidUserRequest)}>Aceitar</button>
                            </div>
                          )
                            : (<div style={{ justifyContent: 'center' }}>
                              <button style={{ backgroundColor: 'rgba(0,255,0,0.5)', border: 1, margin: '4px', flexDirection: 'row', alignItems: 'center', padding: '2px' }}
                                onClick={() => nextStatus(item.status, item.idRequest, item.uidUserRequest, item.amount)}
                              //onClick={() => console.log( item.idRequest)}
                              >
                                <p>Avançar </p>
                                <GrChapterNext color="#0f0" size={15} />
                              </button>
                            </div>
                            )
                          }

                        </td>



                        <td>
                          {/* ticket ultima implementação */}

                          <div style={{ justifyContent: 'center' }}>
                            <span>{item.ticket}</span>
                
                          </div>


                        </td>


                        <td style={{}}>
                          {loadingToFile === false ?
                            (
                              <>
                                <button className="toFile" style={{ backgroundColor: '#3583f6' }}
                                  onClick={() => toFile(item, user.uid)}>
                                  <em color="#fff">Arquivar </em>
                                  <VscFileSubmodule color="#fff" size={17} />
                                </button>
                              </>
                            )
                            : (
                              <>
                                <button className="toFile" style={{ backgroundColor: '#3583f6' }}>
                                  <em color="#fff">Arquivando </em>
                                  <VscFileSubmodule color="#fff" size={17} />
                                </button>
                              </>
                            )}


                        </td>
                        {/* <td data-label="Cadastrado">{item.createdFormated}</td> */}

                        <td data-label="Cadastrado">
                          {/* {item.date.createdHours + ':' + item.date.createdMin + ':' + item.date.createdSec + '\n'
                            + item.date.createdDay + '/' + item.date.createdMonth + '/' + item.date.createdYear} */}
                          {format(toDate(item.created), 'dd/MM/yyyy - HH:mm:s')}
                        </td>

                        <td data-label="#">
                          <button className="action" style={{ backgroundColor: '#3583f6' }} onClick={() => togglePostModal(item)}>
                            <FiSearch color="#fff" size={17} />
                          </button>


                        </td>
                      </tr>
                    )
                  })}


              </tbody>
            </table>

          </>
        )}

      </div>

      {showPostModal && (
        <Modal
          conteudo={detail}
          close={togglePostModal}
          nextStatus={nextStatus}
        />
      )}
      {showRefuseModal && (
        <RefuseModal
          conteudo={detail}
          close={toggleRefuseModal}
          refuseConfirmed={refuse}
        />
      )}

    </div>
  );
}
