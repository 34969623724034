
import '../../Dashboard/dashboard.css';
import './historyrequest.css';
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../contexts/auth';

import Header from '../../../components/Header';
import Title from '../../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { GrHistory } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { format, toDate } from 'date-fns';

import { MdRestaurantMenu } from 'react-icons/md';
import firebase from '../../../services/firebaseConnection';
import Modal from '../../../components/Modal';
import { useHistory, useParams } from 'react-router-dom';
import RequestHistoryModal from '../../../components/RequestHistoryModal';


export default function HistoryRequestMonth() {
  const { idYear, idMonth } = useParams();
  const history = useHistory();
  const year = 'year2022';

  const [showRequestHistoryModal, setShowRequestHistoryModal] = useState(false);

  const { user, deleteRequest } = useContext(AuthContext);

  const [requests, setRequests] = useState([]);
  const [historyRequestMonth, setHistoryRequestMonth] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPostModal, setShowPostModal] = useState(false);
  const [detailRequest, setDetailRequest] = useState();
  const listRef = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsPrevious').doc(idYear).collection(idMonth);


  // useEffect(()=>{
  //   async function loadHistory(){
  //     await firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsPrevious').doc(idYear).collection('month01')
  //     .onSnapshot((doc)=>{
  //       let myHistoryRequest = [];

  //       doc.forEach((item)=>{
  //         myHistoryRequest.push({
  //           id: item.id,
  //           amount: item.data().amount,
  //           itens: item.data().itens
  //         })
  //       });
  //       setHistoryRequestMonth(myHistoryRequest)
  //       console.log(myHistoryRequest);
  //       setLoading(false);
  //     })

  //   }
  //   loadHistory();

  // },[]);
  useEffect(() => {
    async function loadHistoryMonth() {

      console.log('oi')
      await listRef.get()
        .then((snapshot) => {
          let lista = [];

          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              amount: doc.data().amount,
              nomeCliente: doc.data().nomeCliente,
              status: doc.data().status,
              created: doc.data().created,
              finished: doc.data().finished,
              methodPayment: doc.data().methodPayment,
              changeForPayment: doc.data().changeForPayment,
              // createdDateDay: doc.data().createdDateDay,
              // createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy - HH:mm:s'),
              // finishedFormated: format(doc.data().finished.toDate(), 'dd/MM/yyyy - HH:mm:s'),
              itens: doc.data().itens,
              list: doc.data().list,
              mesa: doc.data().mesa,
              uidUserRequest: doc.data().uidUserRequest
            })
          })

          setHistoryRequestMonth(lista);
          console.log(lista)
          setLoading(false);

        })
        .catch((err) => {
          console.log(err + 'DEU ALGUM ERRO!');
        })


    }

    loadHistoryMonth();
  }, [])

  function toggleRequestHistoryModal(item) {
    setShowRequestHistoryModal(!showRequestHistoryModal) // trocando de true para false
    setDetailRequest(item);

  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name={"Histórico de pedidos - Mês" + idMonth}>
            <FiMessageSquare size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando pedidos...{idYear}</span>

          </div>

        </div>
      </div>
    )
  }

  return (
    <div>
      <Header />
      <div className="content">

        <Title name={"Histórico de pedidos | Mês: " + (idMonth === 'month01' ? 'Janeiro'
          : idMonth === 'month02' ? 'Fevereiro'
            : idMonth === 'month03' ? 'Março'
              : idMonth === 'month04' ? 'Abril'
                : idMonth === 'month05' ? 'Maio'
                  : idMonth === 'month06' ? 'Junho'
                    : idMonth === 'month07' ? 'Julho'
                      : idMonth === 'month08' ? 'Agosto'
                        : idMonth === 'month09' ? 'Setembro'
                          : idMonth === 'month10' ? 'Outubro'
                            : idMonth === 'month11' ? 'Novembro'
                              : idMonth === 'month12' ? 'Dezembro'
                                : 'err')}>

          <button style={{ border: 1, backgroundColor: 'rgba(255,255,255,.2)', borderRadius: '2px', padding: 3}}
            onClick={() => history.push(`/historyrequestyear/`)}>
            <em color="#fff">Voltar</em>
          </button>
          <MdRestaurantMenu size={25} />

        </Title>

        {historyRequestMonth.length === 0 ? (

          <div className="container dashboard">
            <span>Nenhum pedido registrado no histórico...</span>
            <br/>
          </div>

        ) : (
          <>

            <table>
              <thead>
                <tr>
                  <th scope="col">Cliente</th>
                  <th scope="col">Itens</th>
                  <th scope="col">Status</th>
                  <th scope="col">Momento do pedido</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                {historyRequestMonth.map((item, index) => {

                  return (
                    <tr key={index}>
                      <td data-label="Cliente">{item.nomeCliente}</td>
                      <td data-label="Assunto">{item.itens}</td>
                      <td data-label="Status">
                        {item.status === 'check' ? (
                          <div>
                            <span className="badge" style={{ backgroundColor: '#ff0', color: '#000', fontWeight: 'normal', }}>Checando</span>
                          </div>
                        )
                          : (<div style={{ justifyContent: 'center' }}>
                            <span className="badge" style={{ backgroundColor: item.status === 'Finalizado' ? '#5cb85c' : item.status === 'PEDIDO RECUSADO' ? '#f00f' : '#e58711' }}>{item.status}</span>

                          </div>
                          )
                        }

                      </td>
                      <td data-label="Cadastrado">
                        {format(toDate(item.created), 'dd/MM/yyyy - HH:mm:s')}
                      </td>
                      <td data-label="#">
                        <button className="action" style={{ backgroundColor: '#3583f6' }} onClick={() => toggleRequestHistoryModal(item)}>
                          <FiSearch color="#fff" size={17} />
                        </button>


                      </td>
                    </tr>
                  )
                })}


              </tbody>
            </table>


          </>
        )}

      </div>

      {showRequestHistoryModal && (
        <RequestHistoryModal
          conteudo={detailRequest}
          close={toggleRequestHistoryModal}
        />
      )}




    </div>
  );
}