import React, { useState, useEffect, useContext } from 'react';
import './CommandOrders.css'

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { format } from 'date-fns';
import { AuthContext } from '../../contexts/auth';
import { GrChapterNext } from 'react-icons/gr';
import { BiArrowFromTop, BiArrowToTop } from 'react-icons/bi'

export default function CommandOrders() {

  const [nomeCliente, setNomeCliente] = useState('')
  const [amount, setAmount] = useState(0);
  const [created, setCreated] = useState('');
  const [close, setClose] = useState('');
  const [formPayment, setFormPayment] = useState('');
  const { idCommand } = useParams();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  let uid = user.uid;
  const [orders, setOrders] = useState([])
  const [stateList, setStateList] = useState('');

  useEffect(() => {

    async function loadOrders() {

      await firebase.firestore().collection('pdvUsers').doc(uid).collection('commandOpen').doc(idCommand).collection('commandOrders').orderBy('created', 'desc').onSnapshot((doc) => {
        let listOrders = [];
        doc.forEach((item) => {
          listOrders.push({
            idOrder: item.id,
            nomeCliente: item.data().nomeCliente,
            status: item.data().status,
            createdFormated: format(item.data().created.toDate(), 'dd/MM/yyyy - HH:mm:s'),
            itens: item.data().itens,
            listaPedidos: item.data().list,
            mesa: item.data().mesa,
            uidUserRequest: item.data().uidUserRequest,
            amount: item.data().amount,
            changeForPayment: item.data().changeForPayment,
            methodPayment: item.data().methodPayment
          })

        });
        setOrders(listOrders);
        // setLoading(false);
      })

      await firebase.firestore().collection('pdvUsers').doc(uid).collection('commandOpen').doc(idCommand).onSnapshot((doc) => {
        setNomeCliente(doc.data().nomeCliente)
        setCreated(format(doc.data().created.toDate(), 'dd/MM/yyyy - HH:mm:s'))

      });


    }

    loadOrders();

    return () => {

    }

  }, [idCommand]);

  async function acceptOrder(status, idOrder, idCommand) {
    console.log(status)
    console.log(idOrder)
    console.log(idCommand)
    nextStatus(status, idOrder, idCommand);
  }

  async function nextStatus(status, idOrder, idCommand) {
    let linkOrders = firebase.firestore().collection('pdvUsers')
      .doc(user.uid).collection('commandOpen')
      .doc(idCommand).collection('commandOrders')
      .doc(idOrder);

    if (status === 'check') {
      await linkOrders.update({ status: 'Em andamento' });
    } else if (status === 'Em andamento') {
      await linkOrders.update({ status: 'Finalizando' });
    } else if (status === 'Finalizando') {
      await linkOrders.update({ status: 'Finalizado' });
    }

  }
  setTimeout(() => calculeAmount(), 5000);
  function calculeAmount() {
    var total = 0
    for (var i = 0; i < orders.length; i++) {
      for (var j = 0; j < orders[i].listaPedidos.length; j++) {
        total += orders[i].listaPedidos[j].valor;
      }

    }
    setAmount(total);
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Detalhes da comanda">

        </Title>
        <div>

        </div>
        <button onClick={() => console.log(orders)}>aa</button>

        <button onClick={() => calculeAmount()}>aa</button>


        <div className="container">
          <section id="detailsCommand">
            <label>Nome do cliente:</label><span>{nomeCliente}</span><p />
            <div id="line" />
            <label>Valor total:</label><span>R$ {parseFloat(amount).toFixed(2)}</span>
            <div id="line" />
            <label>Pagamento:</label><span>Pendente</span><p />
            <div id="line" />
            <label>Abertura:</label><span>{created}</span>
            <div id="line" />
            <label>Fechamento:</label><span>Pendente</span>
            <div id="line" />
            <label>Forma de pagamento:</label><p />
            <div id="line" />
            <label>Troco para:</label><p />
            <div id="line" />
            <label>Troco sugerido:</label><p />
            <div id="line" />
          </section>


          {orders.map((item, index) => {

            return (
              <>
                <section id="sectionCommand">
                  <article><label>{item.createdFormated}</label></article>
                  <article>
                    <button onClick={() => setStateList('')}  >
                      <BiArrowToTop color="#fff" size={20} />
                      <span>Recolher</span>
                    </button>
                    <button onClick={() => setStateList(item.idOrder)}>
                      <BiArrowFromTop color="#fff" size={20} />
                      <span>Listar</span>
                    </button>

                  </article>
                  <article>
                  </article>
                  {/* <article></article>
                  <article style={{ flexDirection: 'row' }}> */}

                  <article >
                    {item.status === 'check' ? (
                      <div style={{ flexDirection: 'row' }}>

                        <button
                          style={{
                            backgroundColor: 'rgba(255,0,0,0.6)',
                            color: '#FFF',
                            border: 1,
                            margin: '4px',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '2px'
                          }}
                        // onClick={() => toggleRefuseModal(item)}
                        >Recusar</button>

                        <button style={{ backgroundColor: 'rgba(0,255,0,0.5)', color: '#333', border: 1, margin: '4px', flexDirection: 'row', alignItems: 'center', padding: '2px' }}
                          onClick={() => acceptOrder(item.status, item.idOrder, idCommand)}
                        >
                          Aceitar</button>
                      </div>
                    )
                      : (<div style={{ justifyContent: 'center' }}>
                        <button style={{ backgroundColor: 'rgba(0,255,0,0.5)', border: 1, margin: '4px', flexDirection: 'row', alignItems: 'center', padding: '2px' }}
                          onClick={() => nextStatus(item.status, item.idOrder, idCommand)}
                        >
                          <p>Avançar </p>
                          <GrChapterNext color="#0f0" size={15} />
                        </button>
                      </div>
                      )
                    }</article>
                  {/* </article> */}

                  <article><label>{orders.length - index}º pedido</label></article>
                </section>


                {/* <tr key={index}>
                  <td>{item.idOrder}</td>
                  <td>{item.createdFormated}</td>
                  <td>{item.itens}</td>
                  <td><button onClick={() => setStateList(item.idOrder)}>Listar</button><button onClick={() => setStateList('')}>Recolher</button></td>
                  <td>linha 1, célula 2</td>
                </tr> */}

                {stateList === item.idOrder ?
                  (<>

                    <section id="sectionItems">


                      <article id="qtd"><label>QTD</label></article>

                      <article id="produto"><label>PRODUTO</label></article>
                      <article id="valor"><label>VALOR UN</label></article>
                      <article id="valor"><label>VALOR TOTAL</label></article>
                      <article id="foto"><label>FOTO</label></article>
                      <article id="obs"><label>OBSERVAÇÃO</label></article>

                    </section>
                    {item.listaPedidos.map((item, index) => {

                      return (
                        <>
                          <div id="line" style={{ margin: 0 }} />
                          <section id="sectionItems">
                            <article id="qtd"><span>{item.qtd}</span></article>
                            <article id="produto"><span>{item.nomeProduto}</span></article>
                            <article id="valor"><span>R$ {parseFloat(item.valorUn).toFixed(2)}</span></article>
                            <article id="valor"><span>R$ {parseFloat(item.valor).toFixed(2)}</span></article>
                            <article id="foto" style={{ padding: 5 }}><img src={item.imageUrl} alt="Foto produto" /></article>
                            <article id="obs"><span>{item.note} asd</span></article>
                          </section>


                          {/* <tr key={index}>
                            <td style={{ width: '4%', backgroundColor: '#777' }} data-label="QTD"></td>
                            <td style={{ width: '4%', backgroundColor: '#ccc' }} data-label="QTD"></td>
                            <td style={{ textAlign: 'left', width: '20%', backgroundColor: '#eee' }} data-label="Produto"></td>
                            <td style={{ textAlign: 'left', width: '20%', backgroundColor: '#ccc' }} data-label="Imagem Produto"></td>
                            <td style={{ textAlign: 'left', width: '66%', backgroundColor: '#eee' }} data-label="Observação do cliente"></td>
                          </tr>
                          <tr><div style={{ backgroundColor: '#000', height: 1 }}></div></tr> */}

                        </>
                      )
                    })}
                  </>)
                  :
                  (<></>)}
                <div id="line" />


              </>
            )
          })}



        </div>

      </div>

    </div>
  );
}