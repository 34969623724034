import React, { useContext } from 'react';
import './header.css';
import { AuthContext } from '../../contexts/auth';
import logo from '../../assets/avatar.png';

import { Link } from 'react-router-dom';
import { FiHome, FiUser, FiSettings } from "react-icons/fi";
import { MdExitToApp, MdRestaurantMenu } from 'react-icons/md';
import { BiListPlus, BiReset } from "react-icons/bi";
import { RiMenuAddFill, RiMenuLine, RiHistoryFill } from 'react-icons/ri';
import {GiReceiveMoney, GiTable} from 'react-icons/gi'


export default function Header() {
  const { user, signOut } = useContext(AuthContext);

  return (
    <div className="sidebar">
      <div>
        <img src={user.logoUrl === null ? logo : user.logoUrl} alt="Foto logo" />
      </div>


      <Link to="/requests">
        <MdRestaurantMenu color="#fff" size="24" />
        <RiMenuLine color="#fff" size="24" />
        Pedidos 
      </Link>
      <Link to="/command-open">
        <MdRestaurantMenu color="#fff" size="24" />
        <RiMenuAddFill color="#fff" size="24" />
        Comandas
      </Link>
      <Link to="/historyrequestyear/">
        <RiHistoryFill color="#fff" size="24" />
        Historico de pedidos
      </Link>
      <Link to="/localflow">
        <GiTable color="#fff" size="24" />
        Mesas e Fluxo
      </Link>
      <Link to="/categories">
        <BiListPlus color="#fff" size="24" />
        Categorias
      </Link>
      {/* <Link to="/dashboard">
      <FiHome color="#fff" size="24"/>
      Chamados
    </Link> */}
      {/* <Link to="/customers">
      <FiUser color="#fff" size="24"/>
      Clientes
    </Link> */}
      <Link to="/contabil">
        <GiReceiveMoney color="#fff" size="24" />
        Contabil
      </Link>
      <Link to="/profile">
        <FiSettings color="#fff" size="24" />
        Configurações
      </Link>

      <button onClick={signOut}>
        <MdExitToApp color="#fff" size="24" />
        Sair
      </button>

    </div>
  );
}