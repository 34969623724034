import { useState, createContext, useEffect } from 'react';
import firebase from '../services/firebaseConnection';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingToFile, setLoadingToFile] = useState(false);
  useEffect(() => {
    function loadStorage() {

      const storageUser = localStorage.getItem('SistemaUser');

      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setLoading(false);
      }

      setLoading(false);

    }

    loadStorage();

  }, []);

  //fazendo login do usuario  
  async function signIn(email, password) {
    setLoadingAuth(true);

    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
      return firebase.auth().signInWithEmailAndPassword(email, password)
    }).then(async (value) => {
      let uid = value.user.uid;

      const userProfile = await firebase.firestore().collection('pdvUsers')
        .doc(uid).get();

      let data = {
        uid: uid,
        authCreate: userProfile.data().authCreate,
        nome: userProfile.data().nome,
        email: value.user.email,
        logoUrl: userProfile.data().logoUrl,
        cnpj: userProfile.data().cnpj,
        logradouro: userProfile.data().logradouro,
        numero: userProfile.data().numero,
        bairro: userProfile.data().bairro,
        cidade: userProfile.data().cidade,
        cep: userProfile.data().cep,
        estado: userProfile.data().estado,
        contato1: userProfile.data().contato1,
        contato2: userProfile.data().contato2,
      };

      if (data.authCreate !== true) {
        alert('Conta bloqueada! \nCaso esteje chegando agora, aguarde contato por email, nossa equipe está trabalhando nisso. \n\n Caso não seja o primeiro login, entre em contado neste email("leeatsdev@gmail.com") para solucionar situação.');
        toast.error('Conta bloqueada! Caso esteje chegando agora, aguarde contato por email, nossa equipe está trabalhando nisso. \n\n Caso não seja o primeiro login, entre em contado neste email("leeatsdev@gmail.com") para solucionar situação.');

        setLoadingAuth(false);
        return;
      }
      else {
        setUser(data);
        storageUser(data);
        setLoadingAuth(false);
        toast.success('Bem vindo!');
      }

    })
      .catch((error) => {
        console.log(error);
        toast.error('Ops! Algo deu errado!');
        setLoadingAuth(false);
      })
  }

  async function signUp(email, password, nome, cnpj) {
    setLoadingAuth(true);

    //criar usuario com email e senha informados
    await firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(async (value) => {
        let uid = value.user.uid;

        //cadastra dados no banco
        await firebase.firestore().collection('pdvUsers')
          .doc(uid).set({
            nome: nome,
            authCreate: false,
            status: false,
            logoUrl: null,
            cnpj: cnpj,
            logradouro: null,
            numero: null,
            bairro: null,
            cidade: null,
            cep: null,
            estado: null,
            contato1: null,
            contato2: null,
          })
          // se cadastrou no DB, mostra dados do BD, 
          // salva no user e no localStorage 
          .then(() => {
            let data = {
              uid: uid,
              nome: nome,
              email: value.user.email,
              logoUrl: null,
              cnpj: cnpj,
              logradouro: null,
              numero: null,
              bairro: null,
              cidade: null,
              cep: null,
              estado: null,
              contato1: null,
              contato2: null,
            }
            //setUser(data);
            //storageUser(data);
            setLoadingAuth(false);
            toast.success('Cadastro Realizado com sucesso! Entraremos em contato através de seu email para efetivação do cadastro.  Duvidas entre em contato com Gabriel Holz através deste email: "leeatsdev@gmail.com".');
            alert('Cadastro Realizado com sucesso! Entraremos em contato através de seu email para efetivação do cadastro.  Duvidas entre em contato com Gabriel Holz através deste email: "leeatsdev@gmail.com".');
          })
      })
      .catch((error) => {
        console.log(error);
        toast.error('Ops! Algo deu errado!');
        setLoadingAuth(false);
      })

  }

  //salva no localStorage para ver se tem usuario logado e se tiver mostra os dados
  async function storageUser(data) {
    localStorage.setItem('SistemaUser', JSON.stringify(data));
  }

  //fazer logout do usuario
  async function signOut() {
    await firebase.auth().signOut();
    localStorage.removeItem('SistemaUser');
    setUser(null);
  }

  async function toFileRequest(item, uidPdv, monthToFile, yearToFile) {
    let idRequest = item.idRequest
    let uidUserRequest = item.uidUserRequest;

    let linkRefStatusRequestPdv = firebase.firestore().collection('pdvUsers').doc(uidPdv)
      .collection('requestsProgress').doc(idRequest);
    //                                      
    let linkRefStatusRequestUser = firebase.firestore().collection('users').doc(uidUserRequest)
      .collection('requestsProgress').doc(idRequest);
    console.log(idRequest);


    confirmAlert({
      title: 'Deseja arquivar este pedido?',
      message: '',
      buttons: [
        {
          label: 'Sim',
          onClick: () => saveDataRequests()

        },
        {
          label: 'Não',
          onClick: () => (setLoadingToFile(false))
        }
      ]
    });
    // }
    //
    async function saveDataRequests() {
      console.log('async function saveDataRequests() {')
      // arquiva os dados dos pedidos em outro diretorio
      await firebase.firestore().collection('pdvUsers').doc(uidPdv).collection('requestsPrevious').doc('year' + yearToFile).collection('month' + monthToFile)
        .doc(idRequest).set(item)//arquiva dados no diretorio do pdv
        .then(() => {
          console.log('.then(() => {')
          firebase.firestore().collection('users').doc(uidUserRequest).collection('requestsPrevious').doc('year' + yearToFile).collection('month' + monthToFile)
            .doc(idRequest).set(item)//arquiva dados no diretorio do usuario
            .then(() => {
              const dbRequestsRef = firebase.database().ref(`pdvs/${user.uid}/requests/${idRequest}`);
              const dbRequestRefUser = firebase.database().ref(`users/${uidUserRequest}/pdvsRequests/${user.uid}/requests/${idRequest}`);
              dbRequestsRef.remove();
              dbRequestRefUser.remove();
              // linkRefStatusRequestPdv.delete();
              // linkRefStatusRequestUser.delete()


              //deleta pedido que estava nos pendendes, deixando apenas no diretorio de finalizados
              // linkRefStatusRequestPdv.delete().then(() => {
              //   linkRefStatusRequestUser.delete().then(() => {
              //     console.log("Documents successfully deleted!");
              //   })
              //     .catch((error) => {
              //       console.error("Error removing document: ", error);
              //     });
              //   console.log("Document successfully deleted!");
              // })
              //   .catch((error) => {
              //     console.error("Error removing document: ", error);
              //   });
              // //                                      

              toast.success('Arquivado com sucesso!')
              setLoadingToFile(false)

            })
            .catch((error) => {
              console.log('....')
              toast.error(error);
            })//

        })
        .catch((error) => {
          toast.error(error);
          console.log('linha 224')
        })

    }

  }

  async function deleteRequest(idRequest, uidPdv) {

    await firebase.firestore().collection('pdvUsers').doc(uidPdv)
      .collection('requestsPrevious').doc(idRequest).delete().then(() => {
        console.log("Document successfully deleted!");
      }).catch((error) => {
        console.error("Error removing document: ", error);
      });

  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signUp,
        signOut,
        signIn,
        loadingAuth,
        setUser,
        storageUser,
        toFileRequest,
        deleteRequest,
        loadingToFile, setLoadingToFile


      }}>
      {children}
    </AuthContext.Provider>
  )

}

export default AuthProvider; 