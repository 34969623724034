import React, { useState } from 'react';
import './refuse-modal.css';

import { FiX } from 'react-icons/fi'

import { GrChapterNext } from 'react-icons/gr';

export default function RefuseModal({ conteudo, close, nextStatus, refuseConfirmed }) {
  const [reasonRefuse, setReasonRefuse] = useState('');
  const [observationsRefuse, setObservationsRefuse] = useState('');
  const [observationsRefuseClient, setObservationsRefuseClient] = useState('');

  function refuse() {
    if (reasonRefuse === '' || observationsRefuse === '' || observationsRefuseClient === '') {
      alert('Preencha todos os campos!')
      return;
    } else {
      refuseConfirmed(conteudo.status,
        conteudo.idRequest,
        conteudo.uidUserRequest,
        reasonRefuse,
        observationsRefuse,
        observationsRefuseClient
      )
    }

  }

  return (
    <div className="modal-refuse">
      <div className="container-refuse">

        <button className="close" onClick={close}>
          <FiX size={23} color="#fff" />
          Voltar
        </button>
        <div>
          <h1>Preencha os campos abaixo para confirmar a recusa do pedido:</h1>
          <div id="line-refuse" />
          <h2 style={{ color: '#f00', fontStyle: 'inherit' }}>Atenção comerciante:</h2>
          <span>Nossa legislação não permite que um estabelecimento comercial recuse atendimento ou venda quando atender o público em geral e, tal atitude configura discriminação e pode levar a até três anos de cadeia.</span>
          <br /><br /><span>A legislação brasileira pode ser considerada avançada quanto à proteção de direitos das minorias. Nossa Lei 7716/89, que recebeu alterações posteriores, possui dispositivos legais precisos quanto à vedação de recusa discriminatória no atendimento. Segue a transcrição.  </span>
          <span>Art. 5º Recusar ou impedir acesso a estabelecimento comercial, negando-se a servir, atender ou receber cliente ou comprador. Pena: reclusão de um a três anos.</span>
          <br /><br />
          <h3>Cientes disso,</h3><span>estamos deixando esta opção de recusa do pedido apenas para casos em que o CLIENTE abandone ou não esteje no estabelecimento.</span>
          <br/><br /><span>Para prosseguir com a recusa, precisamos que seja descrito o motivo da recusa. Onde aponta "Leeats + Estabelecimento" as informações irão ficar disponiveis apensa para a empresa Leeats e o Estabelecimento. Onde está informando "Leeats + Estabelecimento + Cliente" ficará as informações onde ficará disponivel para os três.</span>

          <h3>Informações para:<h3 style={{ color: '#f00' }}> Leeats + Estabelecimento</h3></h3>

          <p /> <p />
          <label>Motivo:</label> <p />
          <input required type="text" value={reasonRefuse} onChange={(e) => setReasonRefuse(e.target.value)} />
          <p />

          <label>Observações:</label> <p />
          <input required type="text" value={observationsRefuse} onChange={(e) => setObservationsRefuse(e.target.value)} />
          <p />

          <div id="line-refuse" />
          <h3>Informações para: <h3 style={{ color: '#f00' }}> Leeats + Estabelecimento + Cliente</h3></h3>
          <label>Observações</label> <p />
          <input required type="text" value={observationsRefuseClient} onChange={(e) => setObservationsRefuseClient(e.target.value)} />
          <p />

        </div>
        <button
          style={{
            backgroundColor: 'rgba(255,0,0,0.6)',
            color: '#FFF',
            border: 1,
            margin: '4px',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '2px'
          }}
          onClick={() => refuse()}>Recusar</button>

      </div>
    </div>
  );
}