
import '../Dashboard/dashboard.css';
import './requests.css';
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../contexts/auth';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { VscFileSubmodule } from 'react-icons/vsc';
import { GrHistory } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { MdRestaurantMenu } from 'react-icons/md';
import { GrChapterNext } from 'react-icons/gr';

import firebase from '../../services/firebaseConnection';
import Modal from '../../components/Modal';
import RefuseModal from '../../components/RefuseModal';


export default function CommandOpen() {

  const [valor, setValor] = useState(0);




  const { user, toFileRequest } = useContext(AuthContext);

  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showRefuseModal, setShowRefuseModal] = useState(false);
  const [detail, setDetail] = useState();
  const listRef = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('commandOpen')//.orderBy('created', 'desc');


  useEffect(() => {

    async function loadRequests() {
      await listRef.onSnapshot((doc) => {
        let listRequests = [];
        doc.forEach((item) => {
          listRequests.push({
            idCommand: item.id,
            //...item.data(),
            nomeCliente: item.data().nomeCliente,
            // status: item.data().status,
            createdFormated: format(item.data().created.toDate(), 'dd/MM/yyy \n HH:mm:s'),
            // itens: item.data().itens,
            // listaPedidos: item.data().list,
            // mesa: item.data().mesa,
            // uidUserRequest: item.data().uidUserRequest,
            // amount: item.data().amount,
            // changeForPayment: item.data().changeForPayment,
            // methodPayment: item.data().methodPayment
          })


        });

        console.log(listRequests)
        setRequests(listRequests);
        setLoading(false);

      })


    }

    loadRequests();

  }, []);

  async function accept(statusMomment, idRequest, uidUserRequest) {
    nextStatus(statusMomment, idRequest, uidUserRequest);
  }

  async function refuse(statusMomment, idRequest, uidUserRequest, reasonRefuse, observationsRefuse, observationsClientRefuse) {


    let linkRefStatusRequestPdv = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsProgress').doc(idRequest);
    let linkRefStatusRequestUser = firebase.firestore().collection('users').doc(uidUserRequest).collection('requestsProgress').doc(idRequest);
    let linkRefStatusMonitoring = firebase.firestore().collection('monitoringSystem').doc('pdvUsers').collection(user.uid).doc('pedidos').collection('requestsProgress').doc(idRequest);

    if (statusMomment === 'check') {

      await linkRefStatusRequestPdv.update({ status: 'PEDIDO RECUSADO', reasonRefuse, observationsRefuse, observationsClientRefuse });
      await linkRefStatusRequestUser.update({ status: 'PEDIDO RECUSADO', observationsClientRefuse });
      await linkRefStatusMonitoring.update({ status: 'PEDIDO RECUSADO', reasonRefuse, observationsRefuse, observationsClientRefuse });

      if (showPostModal === true) {
        togglePostModal();
      }


    }
    setShowRefuseModal(false);
  }

  //editar status
  async function nextStatus(statusMomment, idRequest, uidUserRequest, amount) {
    let linkRefStatusRequestPdv = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsProgress').doc(idRequest);
    let linkRefStatusRequestUser = firebase.firestore().collection('users').doc(uidUserRequest).collection('requestsProgress').doc(idRequest);
    let linkRefStatusMonitoring = firebase.firestore().collection('monitoringSystem').doc('pdvUsers').collection(user.uid).doc('pedidos').collection('requestsProgress').doc(idRequest);
    // firebase.firestore().collection('monitoringSystem').doc('pdvUsers').collection(user.uid).doc('contabil').collection('faturamento').doc('totais').onSnapshot((doc) => {
    //   setValor(doc.data().total_em_vendas)
    // });
    if (statusMomment === 'check') {
      await linkRefStatusRequestPdv.update({ status: 'Em andamento' });
      await linkRefStatusRequestUser.update({ status: 'Em andamento' });
      await linkRefStatusMonitoring.update({ status: 'Em andamento' });
      if (showPostModal === true) {
        togglePostModal();
      }
    }
    else if (statusMomment === 'Em andamento') {
      let newValor = valor + amount;
      if (showPostModal === true) {
        togglePostModal();
      }
      await linkRefStatusRequestPdv.update({ status: 'Finalizado' });
      await linkRefStatusRequestUser.update({ status: 'Finalizado' });
      await linkRefStatusMonitoring.update({ status: 'Finalizado' });
    }



  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal) // trocando de true para false
    setDetail(item);

  }

  //abre modal para passar confirmações de cancelamento
  function toggleRefuseModal(item) {
    setShowRefuseModal(!showRefuseModal) // trocando de true para false
    setDetail(item);

  }


  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Pedidos">
            <FiMessageSquare size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando pedidos...</span>
          </div>

        </div>
      </div>
    )
  }


  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Pedidos">
          <MdRestaurantMenu size={25} />
        </Title>
        <div className="button-history-requests">
          <Link to="/historyrequest">
            <i>Histórico de pedidos</i>
            <GrHistory color="#000" size="24" />
          </Link>
        </div>
        {requests.length === 0 ? (

          <div className="container dashboard">
            <span>Nenhum pedido registrado...</span>
          </div>

        ) : (
          <>

            <table>
              <thead>
                <tr>
                  <th scope="col">Cliente</th>
                  <th scope="col">Inicio</th>
                  <th scope="col">Ultimo pedido</th>
                  <th scope="col">#</th>
                  <th scope="col">#</th>
                  <th scope="col">Momento do pedido</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Cliente">{item.nomeCliente}</td>
                      <td data-label="Inicio">{item.createdFormated}</td>
                      <td data-label="Status">
                        {item.status === 'check' ? (
                          <div>
                            <span className="badge" style={{ backgroundColor: '#ff0', color: '#000', fontWeight: 'normal', }}>Checando</span>

                          </div>
                        )
                          : (<div style={{ justifyContent: 'center' }}>
                            <span className="badge" style={{ backgroundColor: item.status === 'Finalizado' ? '#5cb85c' : item.status === 'PEDIDO RECUSADO' ? '#f00f' : '#e58711' }}>{item.status}</span>

                          </div>
                          )
                        }

                      </td>
                      <td>
                        {item.status === 'check' ? (
                          <div>

                            <button
                              style={{
                                backgroundColor: 'rgba(255,0,0,0.6)',
                                color: '#FFF',
                                border: 1,
                                margin: '4px',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '2px'
                              }}
                              onClick={() => toggleRefuseModal(item)}>Recusar</button>

                            <button style={{ backgroundColor: 'rgba(0,255,0,0.5)', color: '#333', border: 1, margin: '4px', flexDirection: 'row', alignItems: 'center', padding: '2px' }} onClick={() => accept(item.status, item.idRequest, item.uidUserRequest)}>Aceitar</button>
                          </div>
                        )
                          : (<div style={{ justifyContent: 'center' }}>
                            <button style={{ backgroundColor: 'rgba(0,255,0,0.5)', border: 1, margin: '4px', flexDirection: 'row', alignItems: 'center', padding: '2px' }}
                              onClick={() => nextStatus(item.status, item.idRequest, item.uidUserRequest, item.amount)}
                            >
                              <p>Avançar </p>
                              <GrChapterNext color="#0f0" size={15} />
                            </button>
                          </div>
                          )
                        }

                      </td>
                      <td>
                        <button className="toFile" style={{ backgroundColor: '#3583f6' }}
                          onClick={() => toFileRequest(item.idRequest, item.status, item.uidUserRequest, user.uid)}>
                          <em color="#fff">Arquivar </em>
                          <VscFileSubmodule color="#fff" size={17} />
                        </button>
                      </td>
                      <td data-label="Cadastrado">{item.createdFormated}</td>

                      <td data-label="#">
                        {/* <button className="action" style={{ backgroundColor: '#3583f6' }} onClick={() => togglePostModal(item)}>
                          
                        </button> */}
                        <Link className="action" style={{ backgroundColor: '#f6a935' }} to={`/command-open/${item.idCommand}`}>
                          <FiSearch color="#fff" size={17} />
                        </Link>

                      </td>
                    </tr>
                  )
                })}


              </tbody>
            </table>

          </>
        )}

      </div>

      {showPostModal && (
        <Modal
          conteudo={detail}
          close={togglePostModal}
          nextStatus={nextStatus}
        />
      )}
      {showRefuseModal && (
        <RefuseModal
          conteudo={detail}
          close={toggleRefuseModal}
          refuseConfirmed={refuse}
        />
      )}

    </div>
  );
}
