
import '../Dashboard/dashboard.css';
import './historyrequest.css';
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../contexts/auth';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2} from 'react-icons/fi';
import { AiFillDelete} from 'react-icons/ai';
import { GrHistory } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { format, toDate } from 'date-fns';

import { MdRestaurantMenu } from 'react-icons/md';
import firebase from '../../services/firebaseConnection';
import Modal from '../../components/Modal';
  

export default function HistoryRequest() {


  const { user, deleteRequest } = useContext(AuthContext);

  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  const listRef = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsPrevious')//.doc('year2021').collection('month12');
  
  useEffect(()=>{

    async function loadRequests() {
      await listRef.onSnapshot((doc) =>{
        let listRequests = [];
        doc.forEach((item) => {
            listRequests.push({
                idRequest: item.id,
                nomeCliente: item.data().nomeCliente,
                status: item.data().status,
                // createdFormated: format(item.data().created.toDate(), 'dd/MM/yyyy - HH:mm:s' ),
                created: item.data().created,
                finished: item.data().finished,
                itens: item.data().itens,
                list: item.data().list,
                mesa: item.data().mesa,
                uidUserRequest: item.data().uidUserRequest
           })

        });

        setRequests(listRequests);
        setLoading(false);
        console.log(listRequests)
  
      })
    
    }

    loadRequests();

  }, []);

  //editar status
  async function nextStatus(statusMomment, idRequest, uidUserRequest){
    let linkRefStatusRequestPdv = firebase.firestore().collection('pdvUsers').doc(user.uid).collection('requestsProgress').doc(idRequest);
    let linkRefStatusRequestUser = firebase.firestore().collection('users').doc(uidUserRequest).collection('requestsProgress').doc(idRequest);
    if(statusMomment === 'Realizado'){  
      await linkRefStatusRequestPdv.update({status: 'Em andamento'});
      await linkRefStatusRequestUser.update({status: 'Em andamento'});
      togglePostModal();
    }else if(statusMomment === 'Em andamento'){ 
      await linkRefStatusRequestPdv.update({status: 'Finalizando'});
      await linkRefStatusRequestUser.update({status: 'Finalizando'});
      togglePostModal();
    }else if(statusMomment === 'Finalizando'){ 
      togglePostModal();
      await linkRefStatusRequestPdv.update({status: 'Finalizado'});
      await linkRefStatusRequestUser.update({status: 'Finalizado'});
    }

 
 
  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal) // trocando de true para false
    setDetail(item);

  }


  if(loading){
    return(
      <div>
        <Header/>

        <div className="content">
          <Title name="Histórico de pedidos">
              <FiMessageSquare size={25}/>
          </Title>

          <div className="container dashboard">
            <span>Buscando pedidos...</span>
          </div>

        </div>
      </div>
    )
  }


 return (
   <div>
     <Header/>
     <div className="content">
       <Title name="Histórico de pedidos">
          <MdRestaurantMenu  size={25}/>
       </Title>

      {requests.length === 0 ? (

        <div className="container dashboard">
          <span>Nenhum pedido registrado no histórico...</span>
        </div>

      ) : (
        <>

          <table>
            <thead>
              <tr>
                <th scope="col">Cliente</th>
                <th scope="col">Itens</th>
                <th scope="col">Status</th>
                <th scope="col">#</th>
                <th scope="col">Momento do pedido</th>
                <th scope="col">#</th>                
              </tr>
            </thead>
            <tbody>
              {requests.map((item, index)=>{
                return (
                  <tr key={index}>
                    <td data-label="Cliente">{item.nomeCliente}</td>
                    <td data-label="Assunto">{item.itens}</td>
                    <td data-label="Status">
                      <span className="badge" style={{backgroundColor: item.status === 'Finalizado' ? '#5cb85c' : '#e58711'}}>{item.status}</span>
                    </td>
                    <td>
                      <button className="toFile" style={{backgroundColor: 'rgb(255,1,1)'}} 
                        onClick={() => deleteRequest(item.idRequest, user.uid)}>
                        <em color="#fff">Excluir do historico </em>
                        <AiFillDelete color="#fff" size={17}/>
                      </button> 
                    </td>
                    <td data-label="Cadastrado">{format(toDate(item.created), 'dd/MM/yyyy - HH:mm:ss')}</td>
                    <td data-label="#">
                      <button className="action" style={{backgroundColor: '#3583f6'}} onClick={() => togglePostModal(item)}>
                        <FiSearch color="#fff" size={17}/>
                      </button>

                     
                    </td>
                  </tr>
                )
              })} 

            
            </tbody>
          </table>

        </>
      )}
   
     </div>
     
      {showPostModal && (
        <Modal
          conteudo={detail}
          close={togglePostModal}
          nextStatus={nextStatus}
        />
      )}

   </div>
 );
}